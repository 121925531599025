import { env, envEnum } from "../../utils/environment";
import axios from "axios";

function getSettingsForDealer() {
  if (env === envEnum.dev || env === envEnum.local)
    return "https://dev-settings.vinsolutions.com/";
  if (env === envEnum.qa) return "https://qa-settings.vinsolutions.com/";
  if (env === envEnum.staging)
    return "https://staging-settings.vinsolutions.com/";
  return "https://settings.vinsolutions.com/";
}

const baseURL = getSettingsForDealer();

const api = axios.create({
  baseURL,
  timeout: 150000,
  headers: {
    "X-CoxAuto-ReturnNulls": "true",
    Accept: "application/vnd.coxauto.v3+json"
  },
  withCredentials: false
});

// Need dealers list for mock endpoint
export async function getEnterpriseCustomerListDealerToggle(jwt, dealers) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  let response = null;

  try {
    response = await api.get(
      `${baseURL}api/settings/dealer?settingName=enterprisecustomerlist`
    );
  } catch (err) {
    response = err.response;
  }

  return response;
}

// Need dealers list for mock endpoint
export async function getEnterpriseCustomerListAppToggle(jwt) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  let response = null;

  try {
    response = await api.get(
      `${baseURL}api/settings/application?settingName=enterprisecustomerlist`
    );
  } catch (err) {
    response = err.response;
  }

  return response;
}
