import { getMock, loggedInUser } from "../mockData";

// Counter used to assign a roleId to new roles.
let roleId = 1000;

const mockData = getMock();

function delayedPromise(response) {
  return new Promise(resolve => setTimeout(() => resolve(response), 500));
}

export function getEnterpriseUserList(jwt) {
  return delayedPromise({ data: mockData });
}

export function addRole(jwt, role, roles, orgId) {
  const savedRole = { ...role };
  if (!role.id) savedRole.id = roleId++; // just set role id to hard coded high number

  // Mock the real response which returns an href.
  return delayedPromise({ data: { href: "/" + savedRole.id } });
}

export function addRolePermissionsToRole(jwt, role, roles) {
  return delayedPromise(role);
}

export function getPrincipalRoleAsync(jwt, principalId, dealerId) {
  const data = {
    items: [{ href: "testHref/id/1" }]
  };
  return delayedPromise({ data });
}

export function getRolePermissions(jwt, role) {
  const parentRole = mockData.roles.find(p => p.id === role.parentId);
  const granted = role.permissions.filter(
    p => !parentRole.permissions.includes(p)
  );
  const revoked = parentRole.permissions.filter(
    p => !role.permissions.includes(p)
  );

  if (granted.length === 0 && revoked.length === 0) {
    return delayedPromise({ data: { items: [] } });
  }

  return delayedPromise({
    data: {
      items: role.permissions.map(rp => {
        return { permission: `https://localhost/id/${rp}` };
      })
    }
  });
}

export function getRole(jwt, roleId) {
  return delayedPromise();
}

export function addPrincipalToRole(jwt, roleId, principalId, dealerId) {
  return delayedPromise();
}
export function getPermissionsAPIUri() {
  return "https://localhost/id/";
}
export function updatePrincipalRole(
  jwt,
  roleId,
  principalId,
  dealerId,
  principalRoleId
) {
  return delayedPromise();
}

export async function getPrincipalRole(jwt, principalId, dealerId) {
  return delayedPromise({ data: { items: [{ href: "/1" }] } });
}

export function deletePrincipalPermissions(jwt, pp) {
  return delayedPromise();
}

export async function getPrincipalPermissions(jwt, principalId, dealerId) {
  const { users } = getMock();
  const user = users.find(u => u.principalId === principalId);
  const dealer = user.dealers.find(d => d.id === dealerId);
  const permissions = [];
  dealer.permissions.granted.forEach(g => {
    permissions.push({
      href: `https://localhost/principalpermissions/id/1333201`,
      principal: `https://localhost/principals/id/${principalId}`,
      permission: `https://localhost/id/${g}`,
      grant: true,
      scope: `Vin:DealerId:${dealerId}`
    });
  });

  dealer.permissions.revoked.forEach(r => {
    permissions.push({
      href: `https://localhost/principalpermissions/id/1333201`,
      principal: `https://localhost/principals/id/${principalId}`,
      permission: `https://localhost/id/${r}`,
      grant: false,
      scope: `Vin:DealerId:${dealerId}`
    });
  });

  return delayedPromise({
    data: { items: permissions }
  });
}

export async function addPrincipalPermission() {
  return delayedPromise();
}

export function deleteRolePermissions(jwt, rolePermissions) {
  return delayedPromise();
}

export function deleteRole(jwt, role) {
  return delayedPromise();
}

// Old mock funcs
// TODO: Eliminate via funcs above
export function saveUserPermissions(role, user) {
  return delayedPromise({
    role: "User permissions saved."
  });
}

export function getUser(accessToken) {
  // TODO: Call actual API to get logged in user metadata using the accessToken passed in via querystring.
  return delayedPromise(loggedInUser);
}

export function savePermissions(customRoleName, parentId, permissions, roles) {
  const role = {
    name: customRoleName,
    dealerEditable: true,
    parentId,
    // This is assigned by the server in the real api.
    id: roles[roles.length - 1].id + 1,
    permissions: [...permissions],
    numUsersAssigned: 1
  };
  return delayedPromise(role);
}

export function updateRole(user, roleId) {
  return delayedPromise();
}

export function handleRoleForSelectedUsers(jwt, user, roleId) {
  return delayedPromise();
}

export function handleRoleForSingleUser(jwt, payload) {
  // Add bearer token to header to auth with permissions service

  const data = {
    items: payload
  };
  return delayedPromise(data);
}

export function updatePrincipalPermissionsBulk(jwt, payload) {
  // Add bearer token to header to auth with permissions service

  const data = {
    items: payload
  };
  return delayedPromise(data);
}

export function updatePrincipalPermission(jwt = "jwt", payload) {
  // Add bearer token to header to auth with permissions service

  return delayedPromise();
}
export function handleUpdatePrincipalPermissionsAsync(jwt, payload) {
  // Add bearer token to header to auth with permissions service
  return delayedPromise();
}
export function removePrincipalPermissionsAsync(jwt, payload) {
  // Add bearer token to header to auth with permissions service

  return delayedPromise();
}

export function updateRolePermissionsBulk(jwt, payload) {
  return delayedPromise();
}

export function updatePrincipalRolesBulk(jwt, payload) {
  // Add bearer token to header to auth with permissions service

  const data = [
    ...payload.map(bulkOp => {
      return { ...bulkOp, statusCode: bulkOp.action === "ADD" ? 201 : 204 };
    })
  ];
  return delayedPromise({ data });
}
