/* eslint-disable react/no-multi-comp */
import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { user, role } from "../propTypes";
import SubmitButton from "@cx/ui/SubmitButton";
import Button from "@cx/ui/Button";
import RoleDropdown from "./RoleDropdown";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import ModalDialog from "@cx/ui/ModalDialog";
import SimpleTable from "@cx/ui/SimpleTable";
import IconWarning from "@cx/ui/Icons/IconWarning";
import "./MultiUserBar.scss";

function MultiUserBar({
  allRoles,
  filteredSelectedUsers,
  roles,
  onClickAddToDealer,
  onClickCancel,
  onClickRoleCustomize,
  onSubmit
}) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [isSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSelectionsDialog, setShowSelectionsDialog] = useState(false);
  const [disabledRole, setDisabledRole] = useState(true);

  useEffect(() => {
    const enabledDealers = filteredSelectedUsers.filter(
      user => user.dealers.length > 0
    );

    const noDealers = filteredSelectedUsers.filter(
      user => user.dealers.length === 0
    );

    setDisabledRole(
      enabledDealers.length === 0 || noDealers.length > 0 ? true : false
    );
  }, [filteredSelectedUsers]);

  function onRoleChange(eventKey) {
    setSelectedRole(
      eventKey === null ? null : roles.find(p => p.id === eventKey)
    );
  }

  function handleAssignMultiUserClick(event) {
    event.preventDefault();
    setIsLoading(true);
    onSubmit(selectedRole);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setShowConfirmDialog(true);
  }

  function renderSelectedUsersTable() {
    return (
      <SimpleTable hover={false}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
          </tr>
        </thead>
        <tbody>
          {filteredSelectedUsers.map(u => (
            <tr key={u.userName}>
              <td>{u.name}</td>
              <td>{u.userName}</td>
            </tr>
          ))}
        </tbody>
      </SimpleTable>
    );
  }

  function renderSelectionsDialog() {
    return (
      <ModalDialog
        htmlId="assign-by-dealer-selections-dialog"
        header={<ModalDialog.Title>Current Selections</ModalDialog.Title>}
        show
        onHide={() => setShowSelectionsDialog(false)}
        footer={
          <React.Fragment>
            <Button
              buttonStyle="primary"
              onClick={() => setShowSelectionsDialog(false)}
            >
              Close
            </Button>{" "}
          </React.Fragment>
        }
      >
        {renderSelectedUsersTable()}
      </ModalDialog>
    );
  }

  function renderConfirmDialog() {
    return (
      <ModalDialog
        htmlId="assign-by-dealer-confirm-dialog"
        header={<h4>Confirm Assignments</h4>}
        show
        onHide={() => setShowConfirmDialog(false)}
        footer={
          <React.Fragment>
            <Button
              buttonStyle="link"
              onClick={() => setShowConfirmDialog(false)}
            >
              Cancel
            </Button>{" "}
            <SubmitButton
              htmlId="multiuserbar-assignMultiUser"
              className="btn--loader"
              type="submit"
              isLoading={isLoading}
              loadingText="Assigning Roles"
              onClick={handleAssignMultiUserClick}
            >
              Assign Roles
            </SubmitButton>
          </React.Fragment>
        }
      >
        <p>
          <IconWarning className="multiUserBar__confirmDialogWarningIcon" />
          The following users will be assigned to{" "}
          <strong>{selectedRole.name}</strong>.
        </p>
        {renderSelectedUsersTable()}
      </ModalDialog>
    );
  }

  const numSelectedUsers = filteredSelectedUsers.length;
  return (
    <div className="multiuserbar__container">
      {numSelectedUsers === 0 ? (
        <div style={{ marginLeft: 10 }}>No users selected</div>
      ) : (
        <Button
          buttonStyle="link"
          htmlId="multiUserBar__numUsersSelected"
          onClick={() => setShowSelectionsDialog(true)}
          aria-label="Show current selections"
        >
          {numSelectedUsers === 1
            ? "1 user selected"
            : numSelectedUsers + " users selected"}
        </Button>
      )}
      <form onSubmit={handleSubmit}>
        <RoleDropdown
          roles={roles}
          allRoles={allRoles}
          onChange={onRoleChange}
          onCustomize={onClickRoleCustomize}
          selectedRole={selectedRole}
          htmlId="multiuserbar-role-dropdown"
          className="multiuserbar__role-dropdown"
          disabled={disabledRole}
        />
        <Button
          href="#"
          htmlId="assignToDealers"
          buttonStyle="link"
          onClick={onClickAddToDealer}
          style={{ marginRight: 16 }}
          disabled={numSelectedUsers < 1}
          aria-label="Assign to Dealer(s)"
        >
          Assign To Dealer(s)
        </Button>
        <Button
          href="#"
          htmlId="cancelMultiUser"
          buttonStyle="link"
          onClick={onClickCancel}
          style={{ marginRight: 16 }}
          disabled={isSaving}
          aria-label="Cancel multi-user mode"
        >
          Cancel
        </Button>
        <SubmitButton
          htmlId="multiuserbar-assignMultiUser"
          buttonStyle="default"
          className="btn--loader"
          // Hack: Merely here because CX Submit button currently requires it. Can remove when CX no longer requires this prop.
          onClick={() => {}}
          type="submit"
          disabled={
            !selectedRole || filteredSelectedUsers.length === 0 || isSaving
          }
        >
          {isSaving ? (
            <Fragment>
              <LoadingIndicator
                htmlId="MultiUserBarApplyButtonLoadingIndicator"
                size="small"
              />{" "}
              Saving
            </Fragment>
          ) : (
            "Assign Multi-user"
          )}
        </SubmitButton>
      </form>

      {showSelectionsDialog && renderSelectionsDialog()}
      {showConfirmDialog && renderConfirmDialog()}
    </div>
  );
}

MultiUserBar.propTypes = {
  allRoles: PropTypes.arrayOf(role).isRequired,
  filteredSelectedUsers: PropTypes.arrayOf(user).isRequired,
  onClickAddToDealer: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickRoleCustomize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(role).isRequired
};

export default MultiUserBar;
