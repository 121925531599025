import React, { Fragment } from "react";
import RoleDropdown from "./RoleDropdown";
import Checkbox from "react-bootstrap/lib/Checkbox";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";
import TextInput from "@cx/ui/TextInput";
import Badge from "@cx/ui/Badge";
import Alert from "@cx/ui/Alert";
import PropTypes from "prop-types";
import { user, role, permission, dealer, dealerRole } from "../propTypes";
import Popover from "@cx/ui/Popover";
import {
  userHasSamePermissionsForAllDealers,
  getFilteredPermissions
} from "../utils/permissions";
import { arraysEqual } from "../utils/arrays";
// Commented out since we're not grouping by category for now.
// import PermissionCategories from "./PermissionCategories";
import { customRoleNameIsUnique } from "../utils/roles";
import Button from "@cx/ui/Button";
import PermissionCategories from "./PermissionCategories";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import "./PermissionModal.scss";

class PermissionModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      parentRole,
      selectedPermissions
    } = this.getInitialSelectedPermissions();
    this.state = {
      customRoleName: "",
      customRoleChecked: false,
      parentRole,
      selectedPermissions,
      errors: {},
      permissionFilter: "",
      isSaving: false,
      initialPermissions: [selectedPermissions]
    };
  }

  /* The behavior for initially selected permissions varies by scenario:
  Scenario 1 - In multi-user mode: Default to "Select role" and no permissions selected.
  Scenario 2 - Working with a single dealer: Populate parentRole with their current role and select the permissions they currently have.
  Scenario 3 - User has same permissions for all their dealers: Populate parentRole with their current role and select the permissions they currently have.
  Scenario 4 - User has different permissions for each dealer: Initialize the form empty with parentRole set to "None". Also, display a warning at the top of the dialog that the user has different permissions for each dealer.
  */
  getInitialSelectedPermissions() {
    const { users, selectedDealers, roles, allRoles } = this.props;

    // Scenario 1
    if (users.length > 1) {
      return {
        parentRole: null,
        selectedPermissions: []
      };
    }

    // Given check above, we know we're dealing with a single user on all the scenarios below. So can grab sole user from array.
    const user = users[0];

    // Scenario 2 or 3
    const workingWithASingleDealer = selectedDealers.length === 1;
    if (
      workingWithASingleDealer ||
      userHasSamePermissionsForAllDealers(user.dealers)
    ) {
      const role = workingWithASingleDealer
        ? allRoles.find(r => r.id === selectedDealers[0].roleId)
        : allRoles.find(r => r.id === user.dealers[0].roleId);

      let parentRole = null;

      // This is set if the user is assigned to a custom role
      if (role.parentId) {
        // If they're assigned to a custom role, set the base role to their custom role's parent.
        // (Since you're not allowed to create a custom role that's based on a custom role)
        parentRole = roles.find(r => r.id === role.parentId);
      }

      // We determined the user has same permissions for all dealers above,
      // so we can just pull first dealer's permissions to get a list of the user's permissions.
      const { granted, revoked } = selectedDealers[0].permissions;
      return {
        // Set parentRole if one exists (it exists for custom roles). Otherwise, set parentRole to current role.
        parentRole: parentRole || role,
        selectedPermissions: [
          ...role.permissions.filter(p => !revoked.includes(p)),
          ...granted
        ]
      };
    }

    // Scenario 4
    return {
      parentRole: roles.find(r => r.name === "None"),
      selectedPermissions: []
    };
  }

  onRoleChange = eventKey => {
    // If user selected "Select role"...
    if (!eventKey)
      return this.setState({
        parentRole: null,
        selectedPermissions: [],
        customRoleChecked: false
      });

    const parentRole = this.props.roles.find(p => p.id === eventKey);
    this.setState({
      parentRole,
      selectedPermissions: [...parentRole.permissions],
      customRoleChecked: false
    });
  };

  onPermissionChange = event => {
    const { value, checked } = event.target;
    this.setState(state => {
      const selectedPermissions = checked
        ? [...state.selectedPermissions, parseInt(value, 10)]
        : state.selectedPermissions.filter(id => id !== parseInt(value, 10));
      return { selectedPermissions };
    });
  };

  onPermissionFilterChange = event => {
    this.setState({ permissionFilter: event.target.value });
  };

  onCreateCustomRoleChecked = event => {
    this.setState({ customRoleChecked: event.target.checked });
  };

  onCustomRoleNameChange = event => {
    const customRoleName = event.target.value;
    const errors = {};
    if (!customRoleNameIsUnique(customRoleName, this.props.roles)) {
      errors.customRoleName = "Enter a unique role name.";
    }
    this.setState({ customRoleName, errors });
  };

  enableSave = () => {
    if (!this.state.parentRole) return false;
    if (this.state.isSaving) return false;
    if (this.state.customRoleChecked && !this.state.customRoleName)
      return false;

    if (
      this.state.initialPermissions &&
      arraysEqual(
        this.state.initialPermissions[0],
        this.state.selectedPermissions
      )
    )
      return false;

    if (this.state.selectedPermissions.length === 0) return false;
    if (Object.keys(this.state.errors).length > 0) return false;
    return true;
  };

  onClickReset = () =>
    this.setState(state => {
      return {
        selectedPermissions: state.parentRole.permissions,
        customRoleChecked: false,
        customRoleName: ""
      };
    });

  isDefault = () => {
    if (!this.state.parentRole) return true;
    return arraysEqual(
      this.state.selectedPermissions,
      this.state.parentRole.permissions
    );
  };

  // Show warning when in single user mode and user has different permissions for each selected dealer
  showMultiDealerWarning(users, selectedDealers) {
    return (
      users.length === 1 &&
      selectedDealers.length > 1 &&
      !userHasSamePermissionsForAllDealers(selectedDealers)
    );
  }

  getBaseRole() {
    const { parentRole } = this.state;

    if (!parentRole) return null;

    return this.props.roles.find(r => r.id === parentRole.id);
  }

  handleSaveClick = () => {
    this.setState({ isSaving: true });
    const showCustomized = !this.isDefault();
    this.props.onClickSave(
      this.props.users,
      this.props.selectedDealers,
      this.state.parentRole,
      this.state.selectedPermissions,
      this.state.customRoleChecked ? this.state.customRoleName : null,
      showCustomized
    );
  };

  render() {
    const {
      allDealers,
      selectedDealers,
      users,
      roles,
      closeManagePermissions,
      onClickCancel,
      allRoles
    } = this.props;
    const {
      parentRole,
      selectedPermissions,
      customRoleChecked,
      errors,
      isSaving
    } = this.state;
    const showCustomized = !this.isDefault();
    const parentRoleIsCustom = parentRole && parentRole.dealerEditable === true;
    const baseRole = this.getBaseRole();
    const filteredPermissions = getFilteredPermissions(
      this.props.permissions,
      this.state.permissionFilter
    );

    return (
      <Fragment>
        <ModalDialog
          htmlId="PermissionModal"
          className="permissionmodal"
          show
          onHide={closeManagePermissions}
          header={<ModalDialog.Title>Select Permissions</ModalDialog.Title>}
          footer={
            <React.Fragment>
              <SubmitButton
                htmlId="permissionmodal-reset"
                buttonStyle="default"
                type="submit"
                onClick={this.onClickReset}
                disabled={!showCustomized}
                className="permissionmodal__reset"
              >
                Reset to Default
              </SubmitButton>
              <SubmitButton
                htmlId="permissionmodal-cancel"
                buttonStyle="default"
                disabled={isSaving}
                type="submit"
                onClick={onClickCancel}
              >
                Cancel
              </SubmitButton>
              <SubmitButton
                htmlId="permissionmodal-save"
                buttonStyle="primary"
                className="btn--loader"
                type="submit"
                onClick={this.handleSaveClick}
                disabled={!this.enableSave()}
              >
                {isSaving ? (
                  <Fragment>
                    <LoadingIndicator
                      htmlId="PermissionModalSaveLoadingIndicator"
                      size="small"
                      color="white"
                    />{" "}
                    Saving
                  </Fragment>
                ) : (
                  "Save"
                )}
              </SubmitButton>
            </React.Fragment>
          }
        >
          <Fragment>
            <h3 className="permissionmodal__username">
              {users.length > 1
                ? users.length + " users selected"
                : users[0].name}
            </h3>
            <span className="permissionmodal__customizedtext">
              {showCustomized && <Badge>Customized</Badge>}
            </span>
            {this.showMultiDealerWarning(users, selectedDealers) && (
              <Alert htmlId="multiDealerWarning" type="warning">
                This user is currently assigned different permissions for each
                dealer.{" "}
                <strong>
                  This dialog will assign this user the same permissions for all
                  dealers
                </strong>
                . To assign permissions by dealer, close this dialog and select
                "Assign by dealer".
              </Alert>
            )}
            <h5>
              {users.length < 2 ? (
                <Popover
                  htmlId={`permissionmodal-popoverLink`}
                  popoverContent={selectedDealers.map(dealer => (
                    <div key={dealer.id}>
                      {allDealers.find(d => d.id === dealer.id).name}
                    </div>
                  ))}
                  trigger={["click", "outsideClick"]}
                >
                  <Button
                    href="#"
                    buttonStyle="link"
                    style={{ padding: 0 }}
                    onClick={event => {
                      event.preventDefault();
                    }}
                  >
                    {selectedDealers.length} dealer
                    {selectedDealers.length > 1 && "s"}
                  </Button>
                </Popover>
              ) : (
                <div>All Dealers</div>
              )}
            </h5>

            <RoleDropdown
              roles={roles}
              allRoles={allRoles}
              onlyShowDealerEditableRoles
              onChange={this.onRoleChange}
              selectedRole={baseRole}
              htmlId="permissionmodal-roledropdown"
              className="permissionmodal__roledropdown"
              displayCustomize={false}
              showSelectRole
            />
          </Fragment>

          {/* Hide the form until a baseRole is selected */}
          {baseRole && (
            <Fragment>
              <TextInput
                htmlId="permissionmodal-permission-filter"
                label="Filter Permissions"
                layout="horizontal"
                maxLength={50}
                onChange={this.onPermissionFilterChange}
                value={this.state.permissionFilter}
                name="permissionFilter"
              >
                <Button
                  htmlId="manageRoleModalClearFilterButton"
                  onClick={() => this.setState({ permissionFilter: "" })}
                >
                  Clear Filter
                </Button>
              </TextInput>
              <div className="permissionmodal__legend">* Changed</div>
              <br />

              {/* Note that this doesn't group by category. See commented out code below to group permission checkboxes by category */}
              {/* filteredPermissions.length > 0 ? (
                <PermissionCheckboxList
                  key=""
                  heading=""
                  onChange={this.onPermissionChange}
                  permissions={filteredPermissions}
                  parentRole={parentRole}
                  selectedPermissions={selectedPermissions}
                />
              ) : (
                <p style={{ color: "red" }}>No matching permissions found.</p>
              ) */}

              {/* Displaying by category is enabled for now. Use the PermissionCategories component below and comment out the map call above to re-enable grouping by category. */}
              {filteredPermissions.length > 0 ? (
                <PermissionCategories
                  permissions={filteredPermissions}
                  onPermissionChange={this.onPermissionChange}
                  parentRole={parentRole}
                  selectedPermissions={selectedPermissions}
                />
              ) : (
                <p style={{ color: "red" }}>No matching permissions found.</p>
              )}
              <div className="clearfix" />
              <hr />
              {/* Custom roles can't be based on a custom role, so hide form if parent role is custom. */}
              {!parentRoleIsCustom && (
                <React.Fragment>
                  <Checkbox
                    id="create-custom-role-checkbox"
                    className="cx-checkbox"
                    onChange={this.onCreateCustomRoleChecked}
                    disabled={!showCustomized}
                    checked={customRoleChecked}
                  >
                    <div className="cx-checkbox__label">Create Custom Role</div>
                    <div className="cx-checkbox__checkmark" />
                  </Checkbox>
                  {customRoleChecked &&
                    (showCustomized && (
                      <TextInput
                        htmlId="permissionmodal-role-name"
                        label="Custom Role Name"
                        layout="horizontal"
                        maxLength={50}
                        onChange={this.onCustomRoleNameChange}
                        value={this.state.customRoleName}
                        name="customRoleTextBox"
                        error={errors.customRoleName}
                      />
                    ))}
                </React.Fragment>
              )}
            </Fragment>
          )}
        </ModalDialog>
      </Fragment>
    );
  }
}

PermissionModal.propTypes = {
  allDealers: PropTypes.arrayOf(dealer).isRequired,
  allRoles: PropTypes.arrayOf(role).isRequired,
  closeManagePermissions: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(permission).isRequired,
  roles: PropTypes.arrayOf(role).isRequired,
  selectedDealers: PropTypes.arrayOf(dealerRole).isRequired,
  users: PropTypes.arrayOf(user).isRequired
};

export default PermissionModal;
