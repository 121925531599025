import React from "react";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";
import Picky from "./Picky";
import "react-picky/dist/picky.css";
import Button from "@cx/ui/Button";
import "./Filters.scss";

const Filters = ({
  userNameFilter,
  dealers,
  dealerFilter,
  dispatch,
  roles,
  roleFilter,
  numFilteredUsers
}) => {
  const filterIsEnabled = Boolean(
    userNameFilter || dealerFilter.length > 0 || roleFilter.length > 0
  );

  const sortedDealers = dealers.sort((a, b) => a.name.localeCompare(b.name));
  const sortedRoles = roles.sort((a, b) => a.name.localeCompare(b.name));
  const setUserName = event => {
    // this was being dispatched on load results in extra render
    if (event.target.value !== userNameFilter) {
      dispatch(["SET_USERNAME_FILTER", event.target.value]);
    }
  };
  return (
    <div>
      <div className="app--filterWrapper" style={{ width: 350 }}>
        <TextInput
          htmlId="filterUsersByName"
          label="Filter by name or username"
          maxLength={50}
          onChange={setUserName}
          value={userNameFilter}
          name="userNameFilter"
        />
      </div>

      <div className="app--filterWrapper">
        <label htmlFor="filterUsersByDealerDropdown">Filter by dealer</label>
        <br />
        <Picky
          id="filterUsersByDealerDropdown"
          name="dealerFilter"
          className="filters__checkboxdropdown"
          onChange={selectedDealers => {
            dispatch(["SET_DEALER_FILTER", selectedDealers]);
          }}
          options={sortedDealers.map(r => ({
            value: String(r.id),
            label: r.name
          }))}
          valueKey="value"
          labelKey="label"
          numberDisplayed={2}
          multiple
          value={dealerFilter}
          includeSelectAll
          includeFilter
          dropdownHeight={600}
        />
      </div>

      <div className="app--filterWrapper">
        <label htmlFor="filterUsersByRoleDropdown">Filter by role</label>
        <br />
        <Picky
          id="filterUsersByRoleDropdown"
          name="roleFilter"
          className="filters__checkboxdropdown"
          onChange={selectedRoles => {
            dispatch(["SET_ROLE_FILTER", selectedRoles]);
          }}
          options={sortedRoles.map(r => ({
            value: String(r.id),
            label: r.name
          }))}
          valueKey="value"
          labelKey="label"
          numberDisplayed={2}
          multiple
          value={roleFilter}
          includeSelectAll
          includeFilter
          dropdownHeight={600}
        />
      </div>

      <div className="clearfix" />

      <h3>
        <span id="filteredUserCount">
          {numFilteredUsers || "No"} user
          {numFilteredUsers !== 1 && "s"}
        </span>

        <Button
          htmlId="clearFiltersButton"
          // Deliberately using CSS to set visibility hidden so h3 doesn't jump around when this is made visible.
          style={filterIsEnabled ? {} : { visibility: "hidden" }}
          buttonStyle="link"
          onClick={() => dispatch(["CLEAR_FILTERS"])}
        >
          Clear filters
        </Button>
      </h3>
    </div>
  );
};

Filters.propTypes = {
  dealerFilter: PropTypes.array.isRequired,
  dealers: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  numFilteredUsers: PropTypes.number.isRequired,
  roleFilter: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  userNameFilter: PropTypes.string.isRequired
};

export default React.memo(Filters);
