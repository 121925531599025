import React from "react";
import PropTypes from "prop-types";
import { role, permission } from "../propTypes";
import { changedFromDefault } from "../utils/roles";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import "./PermissionCheckBoxList.scss";
import cx from "classnames";
import Tooltip from "@cx/ui/Tooltip";
import { isEnabledForTooltip } from "../utils/permissionsTooltips";

const PermissionCheckboxList = ({
  heading,
  onChange,
  parentRole,
  permissions,
  selectedPermissions
}) => {
  const headingWithoutSpaces = heading.replace(/\s+/g, "");

  return (
    <React.Fragment>
      <h5 style={{ marginTop: 32 }}>{heading}</h5>
      <div>
        {permissions.map(p => {
          const htmlId = headingWithoutSpaces + "-permission-" + p.displayName;
          const checked = selectedPermissions.includes(p.id);
          const toolTip = isEnabledForTooltip(p.displayName);

          return (
            <div key={htmlId} className={cx("permission-checkbox")}>
              <div id={htmlId + "-asterisk"} className="asterisk">
                <span>
                  {changedFromDefault(p.id, checked, parentRole) ? "*" : " "}
                </span>
              </div>
              <input
                type="checkbox"
                onChange={onChange}
                id={htmlId}
                checked={checked}
                value={p.id}
              />{" "}
              <label
                htmlFor={htmlId}
                className={cx({
                  // eslint-disable-next-line
                  enterprise__customer__label:
                    p.displayName === "Access/Enable Enterprise Customer"
                })}
              >
                {p.displayName}
              </label>
              {toolTip.isEnabled && (
                <Tooltip
                  htmlId={p.id + "__popover"}
                  tooltipContent={toolTip.content}
                  position="right"
                  className="popover-tooltip"
                >
                  <IconInfoOutline htmlId="InfoIcon" />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
};

PermissionCheckboxList.propTypes = {
  heading: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  parentRole: role.isRequired,
  permissions: PropTypes.arrayOf(permission).isRequired,
  selectedPermissions: PropTypes.arrayOf(Number).isRequired
};

export default PermissionCheckboxList;
