import { arraysEqual } from "./arrays";

// Returns true if user has same role for all dealers
export function userHasSameRoleForAllDealers(dealers) {
  return dealers.every(x => x.roleId === dealers[0].roleId);
}

// Returns true if user has the same role and permissions for all their dealers
// See test for expected data structure
export function userHasSamePermissionsForAllDealers(dealers) {
  if (dealers.length < 2) return true;
  let samePerms = true;
  dealers.reduce((firstDealer, currentDealer) => {
    // For perf, if samePerms is already false, nothing more to do.
    if (!samePerms) return firstDealer;
    if (firstDealer.roleId !== currentDealer.roleId) samePerms = false;
    if (
      !arraysEqual(
        firstDealer.permissions.granted,
        currentDealer.permissions.granted
      )
    )
      samePerms = false;
    if (
      !arraysEqual(
        firstDealer.permissions.revoked,
        currentDealer.permissions.revoked
      )
    )
      samePerms = false;
    // Just compare each dealer to the first since they must all match
    return firstDealer;
  }, dealers[0]);
  return samePerms;
}

export function userHasACustomizedRole(dealers, roles) {
  return dealers.some(d =>
    permissionsAreCustomized(d.permissions, roles.find(r => r.id === d.roleId))
  );
}

// Returns true if granted or revoked are populated
export function permissionsAreCustomized(permissions, role) {
  const hasGranted = permissions.granted.every(g =>
    role.permissions.includes(g)
  );
  const hasRevoked = permissions.revoked.some(r =>
    role.permissions.includes(r)
  );
  return (
    (permissions.revoked.length > 0 || permissions.granted.length > 0) &&
    (!hasGranted || hasRevoked)
  );
}

// Accepts a list of user dealers and returns true if user has customized roles, but the same role for all dealers.
export function userHasSamePermissionsForAllDealersAndRoleIsCustomized(
  dealers,
  roles
) {
  return userHasSamePermissionsForAllDealers(dealers)
    ? permissionsAreCustomized(
        dealers[0].permissions,
        roles.find(r => r.id === dealers[0].roleId)
      )
    : false;
}

export function getFilteredPermissions(permissions, filter) {
  const lowercaseFilter = filter.toLowerCase();
  if (!lowercaseFilter) return permissions;
  return permissions.reduce((perms, perm) => {
    if (perm.displayName.toLowerCase().includes(lowercaseFilter))
      perms.push(perm);
    return perms;
  }, []);
}
