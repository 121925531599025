// Export enum to avoid typos when referencing environments throughout the app.
export const envEnum = {
  dev: "dev",
  local: "local",
  qa: "qa",
  qts: "qts",
  prod: "prod",
  staging: "staging"
};

function getEnvironment() {
  const { hostname, port } = window.location;
  if (hostname.includes(envEnum.dev)) return envEnum.dev;
  if (hostname.includes(envEnum.local) || port === "3000" || port === "5000")
    return envEnum.local;
  if (hostname.includes(envEnum.qa)) return envEnum.qa;
  if (hostname.includes(envEnum.qts)) return envEnum.qts;
  if (hostname.includes(envEnum.staging)) return envEnum.staging;
  return envEnum.prod;
}

export const env = getEnvironment();

export const isLocalOrDev = env === envEnum.local || env === envEnum.dev;
