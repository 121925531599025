import * as settingsService from "../api/settings";

export async function ecListIsToggledOn(jwt, response) {
  const appToggleResponse = await settingsService.getEnterpriseCustomerListAppToggle(
    jwt
  );

  let appToggledOn = false;

  if (appToggleResponse.status === 200) {
    appToggledOn = appToggleResponse.data.items.some(
      toggle => toggle.value === "true"
    );
  }

  if (appToggledOn) {
    return true;
  } else {
    const dealerToggleResponse = await settingsService.getEnterpriseCustomerListDealerToggle(
      jwt,
      response.data.dealers
    );

    if (dealerToggleResponse.status === 200) {
      return dealerToggleResponse.data.items.some(toggle =>
        response.data.dealers.some(
          dealer => dealer.id === toggle.dealerId && toggle.value === "true"
        )
      );
    }
  }

  return false;
}
