import * as api from "../api";
import { getId } from "./stringHelper";

export async function handleUpdateRolePermission(jwt, existingRole, role) {
  const parentRole = existingRole.find(p => p.id === role.parentId);
  const baseURL = api.getPermissionsAPIUri();

  const grantedPermissions = role.permissions.filter(
    p => !parentRole.permissions.includes(p)
  );
  const revokedPermissions = parentRole.permissions.filter(
    p => !role.permissions.includes(p)
  );

  // get existing role permissions
  const roleData = await api.getRolePermissions(jwt, role);
  const rolePermissions = roleData.data.items;

  const existingPermissions = rolePermissions.map(perm => {
    return getId(perm.permission);
  });
  // only gets the updated grantedPermissions
  const filteredGrantPermissions = grantedPermissions.filter(
    p => !existingPermissions.includes(p)
  );
  // only gets the updated deleted permissions
  const filteredRevokedPermissions = revokedPermissions.filter(
    p => !existingPermissions.includes(p)
  );

  const totalPermissions = [
    ...grantedPermissions.concat([...revokedPermissions])
  ];
  // if prior permissions dont exist we are deleting them
  const permissionsToDelete = existingPermissions.filter(
    p => !totalPermissions.includes(p)
  );

  // handles create new role
  if (
    rolePermissions.length === 0 &&
    (grantedPermissions.length > 0 || revokedPermissions.length > 0)
  ) {
    return createNewCustomRoleFromCustomized(
      baseURL,
      grantedPermissions,
      revokedPermissions,
      role
    );
  }

  // Scenario:1 we delete a role and remove every permission
  // Scenario:2 Reset to default
  if (
    filteredGrantPermissions.length === 0 &&
    filteredRevokedPermissions.length === 0 &&
    permissionsToDelete.length === 0 &&
    existingPermissions.length > 0
  ) {
    return onDeleteAll(rolePermissions);
  }

  return onUpdateRolePermissions(
    baseURL,
    permissionsToDelete,
    filteredGrantPermissions,
    filteredRevokedPermissions,
    rolePermissions,
    role
  );
}

function onUpdateRolePermissions(
  baseURL,
  permissionsToDelete,
  filteredGrantPermissions,
  filteredRevokedPermissions,
  rolePermissions,
  role
) {
  const items = [];
  rolePermissions.forEach(p => {
    permissionsToDelete.forEach(r => {
      if (getId(p.permission) === r) {
        const payload = {
          action: "DELETE",
          item: {
            href: `${p.href}`
          }
        };
        items.push(payload);
      }
    });
  });

  const filteredPermissions = [...filteredGrantPermissions].concat([
    ...filteredRevokedPermissions
  ]);
  filteredPermissions.forEach(permission => {
    const payload = {
      action: "ADD",
      item: {
        role: `${baseURL}roles/id/${role.id}`,
        permission: `${baseURL}atomicpermissions/id/${permission}`,
        grant: filteredGrantPermissions.includes(permission) ? true : false
      }
    };
    items.push(payload);
  });
  return items;
}

function onDeleteAll(rolePermissions) {
  // delete all role permissions if they already exists
  const items = [];
  rolePermissions.forEach(rolePerm => {
    const payload = {
      action: "DELETE",
      item: {
        href: `${rolePerm.href}`
      }
    };
    items.push(payload);
  });
  return items;
}
function createNewCustomRoleFromCustomized(
  baseURL,
  grantedPermissions,
  revokedPermissions,
  role
) {
  const items = [];

  const filteredPermissions = [...grantedPermissions].concat([
    ...revokedPermissions
  ]);

  filteredPermissions.forEach(permission => {
    const payload = {
      action: "ADD",
      item: {
        role: `${baseURL}roles/id/${role.id}`,
        permission: `${baseURL}atomicpermissions/id/${permission}`,
        grant: grantedPermissions.includes(permission) ? true : false
      }
    };
    items.push(payload);
  });
  return items;
}
