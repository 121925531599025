function delayedPromise(response) {
  return new Promise(resolve => setTimeout(() => resolve(response), 500));
}

export function getEnterpriseCustomerListDealerToggle(jwt, dealers) {
  return delayedPromise({
    data: {
      items: dealers.map(d => {
        return {
          dealerId: d.id,
          group: "profilemanager",
          name: "enterprisecustomerlist",
          displayType: "dealersettinggroupboolean",
          displayName: "Enterprise Customer List",
          description:
            "Toggle for whether or not to display Enterprise Customer List",
          value: "true"
        };
      })
    },
    status: 200
  });
}

export function getEnterpriseCustomerListAppToggle(jwt) {
  return delayedPromise({
    data: {
      items: [
        {
          group: "profilemanager",
          name: "enterprisecustomerlist",
          displayType: "dealersettinggroupboolean",
          displayName: "Enterprise Customer List",
          description:
            "Toggle for whether or not to display Enterprise Customer List",
          value: "true"
        }
      ]
    },
    status: 200
  });
}
