import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SimpleTable from "@cx/ui/SimpleTable";
import { role, user, permission } from "../propTypes";
import IconDelete from "@cx/ui/Icons/IconDelete";
import Button from "@cx/ui/Button";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";
import ManageRoleModal from "./ManageRoleModal";
import { newRole } from "../mockData";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { toast } from "@cx/ui/Toast";

class ManageRoles extends React.Component {
  static propTypes = {
    crmUser: PropTypes.bool.isRequired,

    displayShowUserType: PropTypes.bool.isRequired,
    onDeleteRoleClick: PropTypes.func.isRequired,
    onSaveRole: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(permission).isRequired,
    roles: PropTypes.arrayOf(role).isRequired,
    users: PropTypes.arrayOf(user).isRequired
  };

  state = {
    roleToDelete: null,
    roleToEdit: { ...newRole },
    showConfirmDeleteDialog: false,
    showCantDeleteDialog: false,
    usersAssignedToRole: [],
    showManageRole: false,
    isSaving: false
  };

  handleDeleteRoleClick = role => {
    if (this.props.users.some(u => u.dealers.some(d => d.roleId === role.id))) {
      return this.setState({
        showCantDeleteDialog: true,
        usersAssignedToRole: this.props.users.filter(u =>
          u.dealers.some(d => d.roleId === role.id)
        )
      });
    }
    this.setState({
      roleToDelete: role,
      showConfirmDeleteDialog: true,
      usersAssignedToRole: []
    });
  };

  handleConfirmDeleteRole = () => {
    this.setState({ isSaving: true });
    this.props.onDeleteRoleClick(this.state.roleToDelete).then(() => {
      this.setState({ showConfirmDeleteDialog: false, isSaving: false });
    });
  };

  handleCancelDeleteRole = () =>
    this.setState({ showConfirmDeleteDialog: false, roleToDelete: null });

  handleCloseCantDeleteDialog = () =>
    this.setState({
      showCantDeleteDialog: false,
      usersAssignedToRole: []
    });

  showManageRole = role => {
    this.setState({ showManageRole: true, roleToEdit: role });
  };

  handleSaveRole = role => {
    this.props.onSaveRole(role).then(() => {
      this.setState({ showManageRole: false });
      toast.success("Role saved.");
    });
  };

  renderRoleTable(roles, areCustomRoles) {
    return (
      <Fragment>
        <h4>{areCustomRoles ? "Custom Roles" : "System Roles"}</h4>
        <SimpleTable hover={false}>
          <caption className="sr-only">User Roles</caption>
          <thead>
            <tr>
              <th scope="col">Role</th>
              {areCustomRoles && <th scope="col">Based On</th>}
              <th scope="col">Users Assigned</th>
              {areCustomRoles && <th scope="col" />}
            </tr>
          </thead>
          <tbody>
            {roles.map(role => (
              <tr key={role.id}>
                <th scope="row" id={`roleType-${role.name.replace(" ", "")}`}>
                  {areCustomRoles ? (
                    <Button
                      buttonStyle="link"
                      style={{ padding: 0 }}
                      aria-label={"Show " + role.name + " role"}
                      id={"show-custom-role-" + role.id}
                      onClick={() => this.showManageRole(role)}
                    >
                      {role.name}
                    </Button>
                  ) : (
                    role.name
                  )}
                </th>
                {areCustomRoles && (
                  <td>
                    {this.props.roles.find(x => x.id === role.parentId).name}
                  </td>
                )}
                <td>
                  {
                    this.props.users.filter(x =>
                      x.dealers.some(y => y.roleId === role.id)
                    ).length
                  }
                </td>
                {areCustomRoles && (
                  <td>
                    <button
                      onClick={() => this.handleDeleteRoleClick(role)}
                      aria-label={"Delete " + role.name + " role"}
                      id={"delete-role-" + role.id}
                      style={{
                        color: "#2B6BDD",
                        backgroundColor: "transparent",
                        border: 0
                      }}
                    >
                      <IconDelete className="manage-roles-delete-role-icon" />
                      <span className="sr-only">Delete {role.name} role</span>
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </SimpleTable>
      </Fragment>
    );
  }

  render() {
    const { crmUser, displayShowUserType, roles } = this.props;
    const systemRoles = roles.filter(r => r.dealerEditable === false);
    const customRoles = roles.filter(r => r.dealerEditable === true);

    const {
      roleToDelete,
      showCantDeleteDialog,
      usersAssignedToRole,
      isSaving
    } = this.state;

    return (
      <Fragment>
        {/* Note, the key assures the modal remounts when the roleToEdit changes.
        This assures the state is initialized properly in ManageRoleModal
        without having to use getDerivedStateFromProps */}
        {this.state.showManageRole && (
          <div>
            <ManageRoleModal
              crmUser={crmUser}
              displayShowUserType={displayShowUserType}
              key={this.state.roleToEdit ? this.state.roleToEdit.id : 0}
              showModal
              hideModal={() => this.setState({ showManageRole: false })}
              roles={this.props.roles}
              role={this.state.roleToEdit}
              permissions={this.props.permissions}
              onClickSave={this.handleSaveRole}
              onClickCancel={() => this.setState({ showManageRole: false })}
            />
          </div>
        )}

        <ModalDialog
          htmlId="cant-delete-dialog"
          show={showCantDeleteDialog}
          header={
            <ModalDialog.Title>This Role Has Users Assigned</ModalDialog.Title>
          }
          onHide={this.handleCloseCantDeleteDialog}
          displayCloseButton
        >
          These users are still assigned to the role. You must assign these
          users to a different role before you can delete the role.
          <ul>
            {usersAssignedToRole.map(u => (
              <li key={u.name}>{u.name}</li>
            ))}
          </ul>
        </ModalDialog>
        <ModalDialog
          htmlId="manage-roles-delete-dialog"
          show={this.state.showConfirmDeleteDialog}
          onHide={this.onHideDeleteDialog}
          displayCloseButton={false}
          header={<ModalDialog.Title>Confirm Delete</ModalDialog.Title>}
          footer={
            <Fragment>
              <Button
                htmlId="manage-roles-cancel-delete-button"
                onClick={this.handleCancelDeleteRole}
                disabled={isSaving}
              >
                Cancel
              </Button>{" "}
              <SubmitButton
                htmlId="manage-roles-delete-role-button"
                onClick={this.handleConfirmDeleteRole}
                disabled={isSaving}
                className="btn--loader"
              >
                {isSaving ? (
                  <Fragment>
                    <LoadingIndicator
                      htmlId="ManageRolesDeleteLoadingIndicator"
                      size="small"
                      color="white"
                    />{" "}
                    Deleting
                  </Fragment>
                ) : (
                  "Delete"
                )}
              </SubmitButton>
            </Fragment>
          }
        >
          Delete the {roleToDelete && roleToDelete.name} role?
        </ModalDialog>
        <Button
          style={{ marginBottom: 16, float: "right" }}
          htmlId="manage-roles-add-role"
          onClick={() => this.showManageRole(newRole)}
        >
          Add Role
        </Button>
        <div style={{ clear: "both" }} />
        {this.renderRoleTable(systemRoles, false)}
        {customRoles.length > 0 && (
          <div style={{ paddingTop: 24 }}>
            {this.renderRoleTable(customRoles, true)}
          </div>
        )}
      </Fragment>
    );
  }
}

export default ManageRoles;
