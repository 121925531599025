function delayedPromise(response) {
  return new Promise(resolve => setTimeout(() => resolve(response), 500));
}

export function addUsersToDealers(jwt, bulkOperations) {
  return delayedPromise({
    data: bulkOperations.map(bulkOp => {
      return {
        Action: bulkOp.action,
        UserId: bulkOp.userId,
        DealerId: bulkOp.dealerId,
        Success: true,
        ResponseCode: 200
      };
    })
  });
}
