import React from "react";
import PropTypes from "prop-types";
import DropdownMenu from "@cx/ui/DropdownMenu";
import "./RoleDropdown.scss";
import IconWarning from "@cx/ui/Icons/IconWarning";
import Tooltip from "@cx/ui/Tooltip";

const RoleDropdown = ({
  htmlId,
  roles,
  allRoles,
  buttonText,
  className,
  disabled,
  onChange,
  onCustomize,
  onAssignByDealerClick,
  onlyShowDealerEditableRoles,
  showAssignByDealer,
  showSelectRole,
  selectedRole,
  displayCustomize,
  ...props
}) => {
  const visibleRoles = onlyShowDealerEditableRoles
    ? roles.filter(r => r.dealerEditable !== true)
    : roles;

  const selectedRoleName = selectedRole
    ? allRoles.find(r => r.id === selectedRole.id).name
    : null;

  return (
    <React.Fragment>
      {!roles.includes(selectedRole) && selectedRole && (
        <>
          <Tooltip
            htmlId="tooltipLink"
            className="warning__tooltip"
            position="left"
            tooltipContent="Role is a CRM role. Please assign them to a non-crm role."
          >
            <IconWarning className="warningIcon" />
          </Tooltip>
        </>
      )}
      <DropdownMenu
        disabled={disabled}
        aria-label={props["aria-label"]}
        buttonText={selectedRoleName || buttonText}
        buttonStyle="link"
        htmlId={htmlId}
        className={className}
      >
        {showSelectRole && (
          <DropdownMenu.Item
            htmlId={`${htmlId}-select-role`}
            onSelect={onChange}
          >
            Select Role
          </DropdownMenu.Item>
        )}
        {visibleRoles.map(({ id, name }) => {
          return (
            <DropdownMenu.Item
              key={`${htmlId}-${id}`}
              aria-label={"Set role to " + name}
              htmlId={`${htmlId}-${id}`}
              eventKey={id}
              onSelect={onChange}
            >
              {name}
            </DropdownMenu.Item>
          );
        })}
        {displayCustomize && (
          <DropdownMenu.Item
            style={{
              borderTop: "solid 1px #E4E9EF",
              marginTop: 8,
              paddingTop: 8
            }}
            htmlId={`${htmlId}-customize`}
            onSelect={onCustomize}
          >
            Customize
          </DropdownMenu.Item>
        )}
        {showAssignByDealer && (
          <DropdownMenu.Item
            style={{
              borderTop: "solid 1px #E4E9EF",
              marginTop: 8,
              paddingTop: 8
            }}
            htmlId={`${htmlId}-assign-by-dealer`}
            onSelect={onAssignByDealerClick}
          >
            Assign By Dealer
          </DropdownMenu.Item>
        )}
      </DropdownMenu>
    </React.Fragment>
  );
};

RoleDropdown.propTypes = {
  allRoles: PropTypes.array.isRequired,
  "aria-label": PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayCustomize: PropTypes.bool,
  htmlId: PropTypes.string.isRequired,
  onAssignByDealerClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onCustomize: PropTypes.func,
  onlyShowDealerEditableRoles: PropTypes.bool,
  roles: PropTypes.array.isRequired,
  selectedRole: PropTypes.object,
  showAssignByDealer: PropTypes.bool,
  showSelectRole: PropTypes.bool
};

RoleDropdown.defaultProps = {
  buttonText: "Select Role",
  disabled: false,
  displayCustomize: true,
  showAssignByDealer: false,
  showSelectRole: false
};

export default RoleDropdown;
