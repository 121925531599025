// NEEDS REFACTOR
import React from "react";
import PropTypes from "prop-types";
import format from "./lib/format";
import { isDataObject } from "./lib/utils";
import includes from "./lib/includes";
import { onlyUpdateForKeys } from "recompose";
const isEmptyValue = value =>
  value === null ||
  value === undefined ||
  (Array.isArray(value) && !value.length);

const Placeholder = ({
  placeholder,
  value,
  numberDisplayed,
  multiple,
  valueKey,
  labelKey,
  manySelectedPlaceholder,
  allSelectedPlaceholder,
  allSelected
}) => {
  let message = "";
  if (isEmptyValue(value)) {
    message = placeholder;
  } else {
    if (Array.isArray(value) && multiple) {
      // If type is array and values length less than number displayed
      // join the values
      if (value.length <= numberDisplayed) {
        message = value
          .map(opt => {
            if (isDataObject(opt, valueKey, labelKey)) {
              return opt[labelKey];
            }
            return opt;
          })
          .join(", ");
      } else {
        // if many selected and not all selected then use the placeholder
        if (manySelectedPlaceholder && !allSelected) {
          // if it doesn't include the sprintf token then just use the placeholder
          message = includes(manySelectedPlaceholder, "%s")
            ? format(manySelectedPlaceholder, value.length)
            : manySelectedPlaceholder;
          // If all selected and there is an allselectedplaceholder use that
        } else if (allSelected && allSelectedPlaceholder) {
          // if it doesn't include the sprintf token then just use the placeholder
          message = includes(allSelectedPlaceholder, "%s")
            ? format(allSelectedPlaceholder, value.length)
            : allSelectedPlaceholder;
        }
      }
    } else {
      const tempValue = Array.isArray(value) ? value[0] : value;
      if (isDataObject(tempValue, valueKey, labelKey)) {
        message = tempValue[labelKey];
      } else {
        message = tempValue;
      }
    }
  }

  return (
    <span className="picky__placeholder" data-testid="picky_placeholder">
      {message}
    </span>
  );
};

Placeholder.defaultProps = {
  placeholder: "None selected",
  allSelectedPlaceholder: "%s selected",
  manySelectedPlaceholder: "%s selected",
  allSelected: false
};
Placeholder.propTypes = {
  allSelected: PropTypes.bool,
  allSelectedPlaceholder: PropTypes.string,
  labelKey: PropTypes.string,
  manySelectedPlaceholder: PropTypes.string,
  multiple: PropTypes.bool,
  numberDisplayed: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  valueKey: PropTypes.string
};

export default onlyUpdateForKeys([
  "multiple",
  "value",
  "numberDisplayed",
  "allSelected",
  "allSelectedPlaceholder"
])(Placeholder);
