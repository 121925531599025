import { getVinUserId, isVinEmployee } from "../utils/idsHelper";
import { env, envEnum } from "../utils/environment";
import { getId } from "../utils/stringHelper";
import axios from "axios";

export function getPermissionsAPIUri() {
  if (env === envEnum.dev || env === envEnum.local)
    return "https://dev-permissions.vinsolutions.com/";
  if (env === envEnum.qa) return "https://qa-permissions.vinsolutions.com/";
  if (env === envEnum.staging)
    return "https://staging-permissions.vinsolutions.com/";
  return "https://permissions.vinsolutions.com/";
}

const baseURL = getPermissionsAPIUri();

const api = axios.create({
  baseURL,
  timeout: 150000,
  headers: {
    "X-CoxAuto-ReturnNulls": "true",
    Accept: "application/vnd.coxauto.v1+json"
  }
});

export async function getEnterpriseUserList(jwt, dealerId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  // If the person is a Vin Employee use the person they are viewing as.
  // This is the expected behavior determined by Alex McCabe.
  if (isVinEmployee(jwt.claims)) {
    return await getEnterpriseUserPolicy(jwt.selectedILMUserID, dealerId);
  }

  // Otherwise just use the persons Vin User Id from the claims from IDS
  // If Vin User Id is 0, this will return an error saying there isn't any data for user 0
  const vinUserId = getVinUserId(jwt.claims);

  if (vinUserId) {
    return await getEnterpriseUserPolicy(vinUserId, dealerId);
  }

  throw new Error(
    "Could not obtain a valid vin user id from users access token"
  );
}

async function getEnterpriseUserPolicy(userId, dealerId) {
  if (dealerId) {
    try {
      return await api.get(
        `/enterpriseUserPolicy/id/VinConnect:${userId}/dealer/id/${dealerId}`
      );
    } catch (error) {
      // If endpoint isn't deployed, use old endpoint.
      if (error.response.status === 404) {
        return await api.get(`/enterpriseUserPolicy/id/VinConnect:${userId}`);
      }
      throw error;
    }
  } else {
    return await api.get(`/enterpriseUserPolicy/id/VinConnect:${userId}`);
  }
}

export function addRole(jwt, role, roles, orgId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  const data = {
    scope: `Vin:OrgId:${orgId}`,
    dealerEditable: true,
    name: role.name,
    parent: `${baseURL}roles/id/${role.parentId}`
  };

  return api.post(`/roles`, data);
}

export function addRolePermissionsToRole(jwt, role, roles) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  const parentRole = roles.find(p => p.id === role.parentId);
  const granted = role.permissions.filter(
    p => !parentRole.permissions.includes(p)
  );
  const revoked = parentRole.permissions.filter(
    p => !role.permissions.includes(p)
  );

  let rolePermissions = granted.map(g => {
    return {
      role: `${baseURL}roles/id/${role.id}`,
      permission: `${baseURL}atomicpermissions/id/${g}`,
      grant: true
    };
  });

  rolePermissions = rolePermissions.concat(
    revoked.map(r => {
      return {
        role: `${baseURL}roles/id/${role.id}`,
        permission: `${baseURL}atomicpermissions/id/${r}`,
        grant: false
      };
    })
  );

  const rolePermissionsUri = `${baseURL}rolepermissions`;
  return axios.all(rolePermissions.map(rp => api.post(rolePermissionsUri, rp)));
}

export function getRolePermissions(jwt, role) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.get(`${baseURL}rolepermissions?role=${role.id}`);
}

export function getRole(jwt, roleId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.get(`${baseURL}roles/id/${roleId}`);
}

export function addPrincipalToRole(jwt, roleId, principalId, dealerId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  const principalRole = {
    scope: `Vin:DealerId:${dealerId}`,
    principal: `${baseURL}principals/id/${principalId}`,
    role: `${baseURL}roles/id/${roleId}`
  };

  return api.post(`${baseURL}principalroles`, principalRole);
}

export function updatePrincipalRole(
  jwt,
  roleId,
  principalId,
  dealerId,
  principalRoleId
) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  const principalRole = {
    href: `${baseURL}principalroles/id/${principalRoleId}`,
    scope: `Vin:DealerId:${dealerId}`,
    principal: `${baseURL}principals/id/${principalId}`,
    role: `${baseURL}roles/id/${roleId}`
  };

  return api.put(`${baseURL}principalroles`, principalRole);
}

export async function getPrincipalRoleAsync(jwt, principalId, dealerId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.get(
    `${baseURL}principalroles?principal=${principalId}&scope=Vin:DealerId:${dealerId}`
  );
}

export function deleteRolePermissions(jwt, rolePermissions) {
  const rp = rolePermissions.map(rp => getId(rp.href)).join(",");

  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.delete(`${baseURL}rolepermissions/id/list/${rp}`);
}

export function deleteRole(jwt, role) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.delete(`${baseURL}roles/id/${role.id}`);
}

export function getPrincipalPermissions(jwt, principalId, dealerId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.get(
    `${baseURL}principalpermissions?principal=${principalId}&scope=Vin:DealerId:${dealerId}`
  );
}

export function deletePrincipalPermissions(jwt, principalPermissionId) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.delete(
    `${baseURL}principalpermissions/id/${principalPermissionId}`
  );
}

export function addPrincipalPermission(
  jwt,
  principalId,
  permissionId,
  grant,
  dealerId
) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  const principalPermission = {
    scope: `Vin:DealerId:${dealerId}`,
    principal: `${baseURL}principals/id/${principalId}`,
    grant,
    permission: `${baseURL}atomicpermissions/id/${permissionId}`
  };

  return api.post(`${baseURL}principalpermissions`, principalPermission);
}

export async function updatePrincipalRolesBulk(jwt, payload) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;
  const data = {
    items: payload
  };
  return api.post(`${baseURL}principalroles/bulk`, data);
}

export async function updatePrincipalPermissionsBulk(jwt, payload) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;
  const data = {
    items: payload
  };
  return api.post(`${baseURL}principalpermissions/bulk`, data);
}

export async function updateRolePermissionsBulk(jwt, payload) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  const data = {
    requests: payload
  };

  return api.post(`${baseURL}rolepermissions/bulk`, data);
}
