import React from "react";

const tooltipPermissions = [
  {
    name: "Access/Enable Enterprise Customer",
    content:
      "Enable a user to view the Enterprise Customer tab on a Customer Dashboard Record."
  },
  {
    name: "Can Approve Customer Override",
    content: (
      <p>
        Enables user to be an approver for manager overrides in the CRM.
        Approvers will receive an approval code via text/email when an override
        is requested and will see requests sent to them under a new
        CRM&gt;Recent menu.
        <br />
        <br />
        <em>
          *This permission only applies to dealers configured for manager
          override in the CRM and will be functional when launched in early
          2022.
        </em>
      </p>
    )
  },
  {
    name: "View OEM Launchpad Incentives",
    content: (
      <p>
        This only impacts the
        <em>OEM Launch Pad</em> and no other view of incentives.
      </p>
    )
  }
];

// Returns whether
export function isEnabledForTooltip(permissionName) {
  if (!permissionName) return { isEnabled: false, content: "" };

  const toolTip = tooltipPermissions.find(p => p.name === permissionName);

  if (toolTip) return { isEnabled: true, content: toolTip.content };

  return { isEnabled: false, content: "" };
}
