import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import Badge from "@cx/ui/Badge";
import RoleDropdown from "./RoleDropdown";
import {
  userHasACustomizedRole,
  userHasSamePermissionsForAllDealers
} from "../utils/permissions";
import "./UserListCell.scss";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import cx from "classnames";

const UserListCell = ({
  columnIndex,
  rowIndex,
  hoveredRowIndex,
  setHoveredRowIndex,
  style,
  users,
  roles,
  allRoles,
  dispatch,
  allDealers,
  disableFields,
  multiUser,
  onChangeUserRole
}) => {
  const [isSaving, setIsSaving] = useState(false);

  let content = null;
  const user = users[rowIndex];

  async function handleChangeUserRole(roleId, user) {
    setIsSaving(true);
    await onChangeUserRole(roleId, user);
    // TODO: no need to set isSaving false since this gets unmounted when state changes. However, need to add support for concurrent role dropdown changes.
  }

  switch (columnIndex) {
    case 0:
      content = !multiUser ? null : (
        <Fragment>
          <label
            className="sr-only"
            htmlFor={`user-${user.principalId}-multiselect-checkbox`}
          >
            Select {user.userName}
          </label>
          <input
            id={`user-${user.principalId}-multiselect-checkbox`}
            type="checkbox"
            className="cx-checkbox userListCell__checkbox"
            onChange={() => dispatch(["SELECT_USER", user.principalId])}
            checked={user.selected}
          />
        </Fragment>
      );
      break;

    case 1:
      content = <strong>{user.name}</strong>;
      break;

    case 2:
      content = user.userName;
      break;
    case 3:
      content = user.bridgeUserName;
      break;
    case 4:
      // Since the RoleDropdown is expensive to render, hide it while scrolling.
      content = (
        <Button
          buttonStyle="link"
          style={{ padding: 0 }}
          disabled={disableFields || isSaving}
          htmlId={`${user.userName}-dealer-button`}
          onClick={event => {
            //   event.preventDefault();
            dispatch(["SHOW_DEALERS_MODAL", { user }]);
          }}
        >
          {user.dealers.length} {user.dealers.length > 1 ? "dealers" : "dealer"}
        </Button>
      );
      break;

    case 5:
      content = (
        <React.Fragment>
          {user.dealers.length !== 0 ? (
            <React.Fragment>
              {" "}
              <RoleDropdown
                buttonText="Assign by Dealer"
                aria-label={"Select role for " + user.userName}
                roles={roles}
                allRoles={allRoles}
                className="userListCell__roleDropdown"
                // If user has one dealer or the same role for all dealers, select it. Otherwise, leave the dropdown unselected since the user has multiple roles
                selectedRole={
                  user.dealers.length === 1 ||
                  userHasSamePermissionsForAllDealers(user.dealers)
                    ? allRoles.find(r => r.id === user.dealers[0].roleId)
                    : null
                }
                onChange={roleId => handleChangeUserRole(roleId, user)}
                onCustomize={() =>
                  dispatch([
                    "ENABLE_CUSTOMIZE_ROLES",
                    { user, dealers: user.dealers }
                  ])
                }
                onAssignByDealerClick={() =>
                  dispatch(["SHOW_ASSIGN_BY_DEALER", { user }])
                }
                htmlId={`${user.userName}-role-dropdown`}
                showAssignByDealer={user.dealers.length > 1}
                disabled={disableFields || isSaving}
              />
              {userHasACustomizedRole(user.dealers, allRoles) && (
                <Badge
                  aria-label={
                    "The role for " + user.userName + " is customized."
                  }
                  style={{ marginLeft: 16 }}
                >
                  Customized
                </Badge>
              )}
              {isSaving && (
                <LoadingIndicator
                  floatToSide="left"
                  htmlId={"UserListRoleLoadingIndicator" + user.id}
                  size="small"
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      );
      break;

    default:
      throw new Error("Unhandled columnIndex: " + columnIndex);
  }

  return (
    <div
      className={cx({
        userListCell: true,
        "userListCell--hover": hoveredRowIndex === rowIndex,
        userListCell__firstCell: columnIndex === 0, // eslint-disable-line camelcase
        userListCell__lastCell: columnIndex === 5, // eslint-disable-line camelcase
        "userListCell--active": user.selected,
        userListCell__checkboxDiv: columnIndex === 0 // eslint-disable-line camelcase
      })}
      onMouseEnter={() => setHoveredRowIndex(rowIndex)}
      style={style}
    >
      {content}
    </div>
  );
};

UserListCell.propTypes = {
  allDealers: PropTypes.array.isRequired,
  allRoles: PropTypes.array.isRequired,
  columnIndex: PropTypes.number.isRequired,
  disableFields: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  hoveredRowIndex: PropTypes.number,
  multiUser: PropTypes.bool.isRequired,
  onChangeUserRole: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  setHoveredRowIndex: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired
};

export default UserListCell;
