import { getId, getDealerId } from "../utils/stringHelper";

/* eslint-disable no-case-declarations */
function toggleSelectAllUsers(state, users, select) {
  return {
    ...state,
    selectAll: select,
    // Only toggle users which match current filters, if any (users are passed in as the payload)
    users: state.users.map(u => ({
      ...u,
      // If the username is passed, toggle it. Otherwise, don't change it.
      selected: users.find(pu => pu.userName === u.userName)
        ? select // toggle selected to true/false based on the arg passed in.
        : u.selected
    }))
  };
}

export const initialState = {
  assignByDealerPrincipalId: null,
  loggedInUser: { dealers: [] },
  selectedUser: { dealers: [] },
  selectedDealers: [],
  dealers: [],
  ecDealers: [],
  users: [],
  allRoles: [],
  roles: [],
  permissions: [],
  crmUser: true,
  showManageRoles: false,
  showManagePermissions: false,
  showCustomizeRoles: false,
  showAssignByDealer: false,
  showMultiUserBar: false,
  showDealersModal: false,
  selectAll: false,
  isLoading: true,
  managePermissionsMultipleUsers: false,
  manageDealersMulitpleUsers: false,
  userNameFilter: "",
  roleFilter: [],
  dealerFilter: [],
  jwt: null,
  error: null,
  forbidden: false,
  isInit: false,
  isEcSettingToggleEnabled: false,
  displayECPermission: false
};

// Returns object with revoked/granted permissionIds
function getCustomizedPermissions(parentRole, selectedPermissions) {
  const revoked = parentRole.permissions.reduce((acc, cur) => {
    if (!selectedPermissions.includes(cur)) acc.push(cur);
    return acc;
  }, []);

  const granted = selectedPermissions.reduce((acc, cur) => {
    if (!parentRole.permissions.includes(cur)) acc.push(cur);
    return acc;
  }, []);

  return { revoked, granted };
}

// Returns an array of users with selected set to false for all.
const getAllUsersUnselected = users =>
  users.map(u => {
    // Nothing to change, so just return untouched (faster for performance).
    if (!u.selected) return u;
    return { ...u, selected: false };
  });

export function appReducer(state, [actionType, payload]) {
  switch (actionType) {
    case "ERROR_GETTING_JWT":
      return {
        ...state,
        error: "Error getting JWT",
        isLoading: false
      };

    case "USER_NOT_AUTHORIZED":
      return {
        ...state,
        forbidden: true,
        isLoading: false
      };

    case "UPDATE_JWT":
      return {
        ...state,
        jwt: payload,
        isInit: true
      };

    case "ERROR_GETTING_USERS":
      return {
        ...state,
        error: payload,
        isLoading: false
      };

    case "API_ERROR":
      return {
        ...state,
        error: payload,
        isLoading: false
      };

    case "LOAD_INITIAL_DATA":
      return {
        ...state,
        // Add the selected property for use in the UI since not returned from the API.
        users: payload.enterpriseUserList.users.map(u => ({
          ...u,
          selected: false
        })),
        isLoading: false,
        dealers: payload.enterpriseUserList.dealers,
        allRoles: payload.enterpriseUserList.roles,
        roles: payload.enterpriseUserList.roles.filter(r => r.roleType === 1),
        displayECPermission: payload.isEcSettingToggleEnabled,
        permissions: payload.enterpriseUserList.permissions,
        orgId: payload.enterpriseUserList.orgId,
        ecDealers: payload.ecDealers.map(d => d.id),
        isEcSettingToggleEnabled: payload.isEcSettingToggleEnabled // remove this once we go away from settings services
      };

    case "SET_USER_ROLE":
      let updatedUser = null;
      const usersWithUpdatedRoles = state.users.map(u => {
        // var userToUpdate = payload.user.filter(
        //   (up) => up.user.userName === u.userName
        // );
        if (payload.user.userName === u.userName) {
          // so the user to update...
          updatedUser = {
            ...u,
            dealers: u.dealers.map(d => {
              const dealerIdPassedAndMatches =
                payload.dealerId && d.id === payload.dealerId;
              // If dealerId is passed, only update matching dealer. DealerId is passed for assignByDealer.
              // If no dealerId is passed, update all user's dealers.
              // User CRM Access must match to what the current user type is
              return (!payload.dealerId || dealerIdPassedAndMatches) &&
                d.hasCrmAccess === state.crmUser
                ? {
                    ...d,
                    roleId: payload.roleId,
                    permissions: { revoked: [], granted: [] }
                  }
                : d;
            })
          };
          return updatedUser;
        }
        // Not the user to update, so return untouched user.
        return u;
      });

      return {
        ...state,
        users: usersWithUpdatedRoles
      };

    case "ENABLE_CUSTOMIZE_ROLES":
      return {
        ...state,
        showManagePermissions: true,
        selectedUser: payload.user,
        selectedDealers: payload.dealers
      };

    case "SHOW_ASSIGN_BY_DEALER":
      return {
        ...state,
        showAssignByDealer: true,
        assignByDealerPrincipalId: payload.user.principalId
      };

    case "ENABLE_LOADING":
      return { ...state, isLoading: true };

    case "APPLY_PERMISSIONS_TO_MULTIPLE_USERS":
      return {
        ...state,
        showMultiUserBar: false,
        users: state.users.map(u => {
          return payload.selectedUsers.includes(u)
            ? {
                ...u,
                selected: false, // deselect since multi-select action is now complete.
                dealers: u.dealers.map(d => {
                  if (d.hasCrmAccess === state.crmUser) {
                    return {
                      ...d,
                      roleId: payload.role.id,
                      permissions: { granted: [], revoked: [] }
                    };
                  } else {
                    return d;
                  }
                })
              }
            : u;
        })
      };

    case "DISABLE_MULTI_USER":
      return {
        ...state,
        showMultiUserBar: false,
        users: getAllUsersUnselected(state.users)
      };

    case "ENABLE_MULTI_USER":
      return {
        ...state,
        showMultiUserBar: true,
        users: getAllUsersUnselected(state.users)
      };

    case "CLOSE_MANAGE_PERMISSIONS":
      return {
        ...state,
        showManagePermissions: false,
        managePermissionsMultipleUsers: false
      };

    case "DELETE_ROLE":
      return {
        ...state,
        roles: state.roles.filter(r => r.id !== payload.role.id)
      };

    case "SHOW_MANAGE_ROLES":
      return { ...state, showManageRoles: true };

    case "SAVE_PERMISSIONS":
      return {
        ...state,
        showManagePermissions: false,
        managePermissionsMultipleUsers: false,
        showMultiUserBar: false,
        users: state.users.map(user => {
          // So, if user is selected...
          if (
            payload.selectedUsers
              .map(u => u.principalId)
              .includes(user.principalId)
          ) {
            // set their role and additional permissions
            const dealers = user.dealers.map(dealer => {
              const isMultiUserSave = payload.selectedUsers.length > 1;
              const dealerIsSelected = payload.selectedDealers
                .map(d => d.id)
                .includes(dealer.id);

              // If multi-user save, update permissions for all dealers.
              // If single user save, only update permissions if that dealer is passed into the list of selected dealers.
              if (
                (isMultiUserSave || dealerIsSelected) &&
                dealer.hasCrmAccess === state.crmUser
              ) {
                // set the new role and additional permissions for the dealer
                return {
                  ...dealer,
                  roleId: payload.roleId, // If a custom role was just created, no permissions can possibly be set as customized, so set revoked and granted to empty.
                  permissions: payload.customRoleName
                    ? { revoked: [], granted: [] }
                    : getCustomizedPermissions(
                        payload.parentRole,
                        payload.permissions
                      )
                };
              } else {
                return dealer;
              }
            });

            return {
              ...user,
              customized: payload.isCustomized && !payload.customRoleName,
              dealers
            };
          } else {
            return user;
          }
        })
      };

    case "SHOW_CUSTOMIZE_ROLE":
      return {
        ...state,
        showManagePermissions: true,
        managePermissionsMultipleUsers: payload.users.length > 1,
        selectedUser:
          payload.users.length > 1 ? { dealers: [] } : payload.users[0],
        // If only 1 user has been selected, select that user's dealers.
        // Otherwise, set to an empty array, since multi-user mode ignores selectedDealers
        // because it applies to all dealers.
        selectedDealers:
          payload.users.length === 1 ? payload.users[0].dealers : []
      };

    case "CLEAR_FILTERS":
      return {
        ...state,
        userNameFilter: "",
        roleFilter: [],
        dealerFilter: [],
        selectAll: false
      };

    case "ADD_ROLE":
      return {
        ...state,
        roles: [...state.roles, payload],
        allRoles: [...state.allRoles, payload]
      };

    case "EDIT_ROLE":
      return {
        ...state,
        roles: state.roles.map(r => {
          return r.id === payload.id ? payload : r;
        }),
        allRoles: state.allRoles.map(r => {
          return r.id === payload.id ? payload : r;
        })
      };

    case "SELECT_USER":
      return {
        ...state,
        users: state.users.map(u => {
          return u.principalId === payload
            ? { ...u, selected: !u.selected }
            : u;
        })
      };

    case "SELECT_ALL_USERS":
      return toggleSelectAllUsers(state, payload, true);

    case "DESELECT_ALL_USERS":
      return toggleSelectAllUsers(state, payload, false);

    case "CLOSE_ASSIGN_BY_DEALER":
      return { ...state, showAssignByDealer: false };

    case "HIDE_MANAGE_ROLES":
      return { ...state, showManageRoles: false };

    case "SET_USERNAME_FILTER":
      return { ...state, userNameFilter: payload, selectAll: false };

    case "SET_DEALER_FILTER":
      return { ...state, dealerFilter: payload, selectAll: false };

    case "SET_ROLE_FILTER":
      return { ...state, roleFilter: payload, selectAll: false };

    case "SHOW_DEALERS_MODAL":
      return {
        ...state,
        showDealersModal: true,
        selectedUser: payload && payload.user ? payload.user : undefined
      };

    case "HIDE_DEALERS_MODAL":
      return {
        ...state,
        showDealersModal: false
      };

    case "SHOW_DEALERS_CONFIRM_MODAL":
      return {
        ...state,
        showDealerConfirmDialog: true,
        bulkOperations: payload.bulkOperations,
        showDealersModal: false
      };

    case "HIDE_DEALERS_CONFIRM_MODAL":
      return {
        ...state,
        showDealerConfirmDialog: false,
        bulkOperations: [],
        showMultiUserBar: false,
        users: getAllUsersUnselected(state.users)
      };

    case "ASSIGN_USER_DEALERS":
      const bulkOps = payload.response.data;
      const enterpriseSalespersonRole = state.allRoles.find(
        r => r.name.replace(/\s/g, "") === "EnterpriseSalesperson"
      );
      const users = state.users.map(u => {
        const userBulkOps = state.crmUser
          ? bulkOps.filter(bo => bo.UserId === u.userId)
          : bulkOps.filter(bo => getId(bo.item.principal) === u.principalId);

        userBulkOps.forEach(uBulkOp => {
          if (uBulkOp && state.crmUser) {
            if (uBulkOp.Success) {
              if (uBulkOp.Action.toUpperCase() === "ADD") {
                const nonCrmUserDealer = u.dealers.find(
                  d => d.id === uBulkOp.DealerId
                );

                if (nonCrmUserDealer) {
                  // Reset user to CRM Salesperson access
                  nonCrmUserDealer.hasCrmAccess = true;
                  nonCrmUserDealer.roleId = 2;
                  nonCrmUserDealer.permissions.granted = [];
                  nonCrmUserDealer.permissions.revoked = [];
                } else {
                  // Role didn't exist in dealership before, need to push new dealership access.
                  u.dealers.push({
                    id: uBulkOp.DealerId,
                    roleId: 2,
                    permissions: {
                      granted: [],
                      revoked: []
                    },
                    hasCrmAccess: state.crmUser
                  });
                }
              } else {
                const index = u.dealers.findIndex(
                  d => d.id === uBulkOp.DealerId
                );
                u.dealers.splice(index, 1);
              }
            }
          }

          if (uBulkOp && !state.crmUser) {
            if (uBulkOp.statusCode === 201 || uBulkOp.statusCode === 204) {
              if (uBulkOp.action.toUpperCase() === "ADD") {
                u.dealers.push({
                  id: getDealerId(uBulkOp.item.scope),
                  roleId: enterpriseSalespersonRole.id,
                  permissions: {
                    granted: [],
                    revoked: []
                  },
                  hasCrmAccess: state.crmUser
                });
              } else {
                const index = u.dealers.findIndex(
                  d => d.id === getDealerId(uBulkOp.item.scope)
                );
                u.dealers.splice(index, 1);
              }
            }
          }
        });

        return { ...u, selected: false };
      });
      return {
        ...state,
        users,
        showDealersModal: false,
        showDealerConfirmDialog: false,
        showMultiUserBar: false
      };

    case "TOGGLE_USER_TYPE":
      return {
        ...state,
        crmUser: !state.crmUser,
        roles: !state.crmUser
          ? state.allRoles.filter(r => r.roleType === 1)
          : state.allRoles.filter(r => r.roleType === 2),
        roleFilter: []
      };

    default:
      throw new Error("Unknown action type: " + actionType);
  }
}
