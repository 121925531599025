import React from "react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";

function ForbiddenPage() {
  return (
    <Grid htmlId="GridExample">
      <Row className="show-grid">
        <Col xsHidden md={4} />
        <Col xs={12} md={4}>
          <h1>
            Sorry, you have navigated to a page you do not have permissions to
            see.
          </h1>
          <p>
            {
              "Please contact your dealership's administrator if you need permissions to view this page."
            }
          </p>
        </Col>
        <Col xsHidden md={4} />
      </Row>
    </Grid>
  );
}

export default ForbiddenPage;
