function delayedPromise(response) {
  return new Promise(resolve => setTimeout(() => resolve(response), 500));
}

export function getEnterpriseSettingForDealer(jwt, dealers) {
  return delayedPromise({
    data: {
      dealerIdList: dealers
    },
    status: 200
  });
}
