import React, { Fragment, useState } from "react";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import SimpleTable from "@cx/ui/SimpleTable";
import IconComplete from "@cx/ui/Icons/IconComplete";
import IconClose from "@cx/ui/Icons/IconClose";
import { user, dealer, bulkOperation } from "../propTypes";
import PropTypes from "prop-types";
import { getId, getDealerId } from "../utils/stringHelper";

function ConfirmDealerChangesModal({
  bulkOperations,
  onClickCancel,
  onClickSave,
  crmUser,
  users,
  dealers
}) {
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveClick = () => {
    setIsSaving(true);
    onClickSave(bulkOperations);
  };

  return (
    <Fragment>
      <ModalDialog
        htmlId="assign-dealer-confirm-dialog"
        header={<h4>Confirm Assignments</h4>}
        show
        onHide={onClickCancel}
        footer={
          <React.Fragment>
            <Button buttonStyle="link" onClick={onClickCancel}>
              Cancel
            </Button>{" "}
            <SubmitButton
              htmlId="multidealermodal-assignMultiUser"
              className="btn--loader"
              type="submit"
              isLoading={isSaving}
              loadingText="Saving Changes"
              onClick={handleSaveClick}
            >
              Save Changes
            </SubmitButton>
          </React.Fragment>
        }
      >
        <SimpleTable htmlId="UserDealerTable" bordered>
          <thead>
            <tr>
              <th scope="col">User</th>
              <th scope="col">Dealer Status</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              return (
                <tr key={user.username}>
                  <th scope="row">{user.name}</th>
                  <th>
                    {bulkOperations.map(bulkOp => {
                      const dealer = dealers.find(
                        dealer =>
                          dealer.id ===
                            (crmUser
                              ? bulkOp.dealerId
                              : getDealerId(bulkOp.item.scope)) &&
                          (crmUser
                            ? user.userId === bulkOp.userId
                            : user.principalId === getId(bulkOp.item.principal))
                      );

                      if (bulkOp.action.toLowerCase() === "add" && dealer) {
                        return (
                          <div>
                            <IconComplete className="addedIcon" />
                            <span>{dealer.name}</span>
                          </div>
                        );
                      }

                      if (bulkOp.action.toLowerCase() === "delete" && dealer) {
                        return (
                          <div
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "#DD2B2B"
                            }}
                          >
                            <IconClose className="removedIcon" />
                            <span>{dealer.name}</span>
                          </div>
                        );
                      }

                      return (
                        <div
                          key={`${bulkOp.userId}-${bulkOp.dealerId}-FallThrough`}
                        />
                      );
                    })}
                  </th>
                </tr>
              );
            })}
          </tbody>
        </SimpleTable>
      </ModalDialog>
    </Fragment>
  );
}

ConfirmDealerChangesModal.propTypes = {
  bulkOperations: PropTypes.arrayOf(bulkOperation).isRequired,
  crmUser: PropTypes.bool.isRequired,
  dealers: PropTypes.arrayOf(dealer).isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(user).isRequired
};

export default ConfirmDealerChangesModal;
