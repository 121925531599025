import React from "react";
import PropTypes from "prop-types";
import { onlyUpdateForKeys } from "recompose";
import { isDataObject } from "./lib/utils";

const Option = props => {
  const {
    id,
    item,
    isSelected,
    labelKey,
    valueKey,
    selectValue,
    style,
    multiple,
    tabIndex,
    disabled
  } = props;
  const cssClass = isSelected ? "option selected" : "option";
  const body = isDataObject(item, labelKey, valueKey) ? item[labelKey] : item;
  const inputType = multiple ? "checkbox" : "radio";
  const select = () => !disabled && selectValue(item);

  return (
    <div
      tabIndex={tabIndex}
      id={id}
      role="option"
      style={style}
      data-selected={isSelected ? "selected" : ""}
      aria-selected={isSelected}
      className={cssClass}
      onClick={select}
      onKeyPress={e => {
        e.preventDefault();
        if (!disabled) {
          selectValue(item);
        }
      }}
    >
      <input
        type={inputType}
        readOnly
        tabIndex={-1}
        disabled={disabled}
        checked={isSelected}
        aria-label={body}
      />
      {body}
    </div>
  );
};

Option.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  item: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]).isRequired,
  labelKey: PropTypes.string,
  multiple: PropTypes.bool,
  selectValue: PropTypes.func.isRequired,
  style: PropTypes.object,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueKey: PropTypes.string
};
export default onlyUpdateForKeys([
  "multiple",
  "isSelected",
  "id",
  "item",
  "tabIndex"
])(Option);
