import * as api from "../api";
import { getId } from "../utils/stringHelper";

export async function handleUpdatePrincipalPermissionsAsync(
  jwt,
  principalDealersToUpdate
) {
  try {
    const baseURL = api.getPermissionsAPIUri();
    const principalPermissionsList = [];
    let count = 1;
    for (const pd of principalDealersToUpdate) {
      let removedGrantedPermission = [];
      let removedRevokedPermission = [];
      // we need to add these grant these permissions if role change and pp is selected
      const grantedPermissionsToKeep = pd.customizedPermissions.granted.filter(
        x => pd.originalPermissions.granted.includes(x)
      );

      // granted permissions added to customized
      const grantedPermissions = pd.customizedPermissions.granted.filter(
        x => !pd.originalPermissions.granted.includes(x)
      );

      // granted permissions removed from customized
      if (pd.originalPermissions.granted.length !== 0) {
        removedGrantedPermission = pd.originalPermissions.granted.filter(
          x => !pd.customizedPermissions.granted.includes(x)
        );
      }
      // revoked permission removed from customized
      if (pd.originalPermissions.revoked.length !== 0) {
        removedRevokedPermission = pd.originalPermissions.revoked.filter(
          x => !pd.customizedPermissions.revoked.includes(x)
        );
      }
      // revoked permissions added
      const revokedPermissions = pd.customizedPermissions.revoked.filter(
        x => !pd.originalPermissions.revoked.includes(x)
      );

      if (revokedPermissions.length > 0 || grantedPermissions.length > 0) {
        const addGrantedPermissions = await handleAddedPermissionsAsync(
          pd,
          grantedPermissions,
          revokedPermissions,
          baseURL,
          count
        );
        count = addGrantedPermissions.counter;

        principalPermissionsList.push(
          addGrantedPermissions.addedPermissionsList
        );
      }

      if (
        removedGrantedPermission.length > 0 ||
        removedRevokedPermission.length > 0
      ) {
        const removeGranted = removedGrantedPermission.filter(
          x => !grantedPermissionsToKeep.includes(x)
        );
        const deletePermissions = await handleDeletePermissionsAsync(
          jwt,
          pd,
          removeGranted,
          removedRevokedPermission,
          baseURL,
          count
        );
        count = deletePermissions.counter;

        principalPermissionsList.push(
          deletePermissions.deletePrincipalPermissionsList
        );
      }
    }
    return principalPermissionsList.flat();
  } catch (error) {
    return error;
  }
}

// permissions added to customized list
async function handleAddedPermissionsAsync(
  pd,
  grantedPermissions,
  revokedPermissions,
  baseURL,
  count
) {
  const addedPermissionsList = [];
  let counter = count;
  for (const granted of grantedPermissions) {
    const payload = {
      identifier: counter,
      action: "ADD",
      item: {
        scope: `Vin:DealerId:${pd.dealerId}`,
        principal: `${baseURL}principals/id/${pd.user.principalId}`,
        permission: `${baseURL}atomicpermissions/id/${granted}`,
        grant: true
      }
    };
    addedPermissionsList.push(payload);
    counter++;
  }

  for (const revoked of revokedPermissions) {
    const payload = {
      identifier: counter,
      action: "ADD",
      item: {
        scope: `Vin:DealerId:${pd.dealerId}`,
        principal: `${baseURL}principals/id/${pd.user.principalId}`,
        permission: `${baseURL}atomicpermissions/id/${revoked}`,
        grant: false
      }
    };
    addedPermissionsList.push(payload);
    counter++;
  }

  return {
    addedPermissionsList,
    counter
  };
}

// permissions removed from customized list
async function handleDeletePermissionsAsync(
  jwt,
  pd,
  removedGrantedPermission,
  removedRevokedPermission,
  baseURL,
  count
) {
  let counter = count;
  if (
    removedRevokedPermission.length > 0 ||
    removedGrantedPermission.length > 0
  ) {
    try {
      const response = await api.getPrincipalPermissions(
        jwt,
        pd.user.principalId,
        pd.dealerId
      );

      const deletePrincipalPermissionsList = [];
      const permissions = [];
      for (const pp of response.data.items) {
        // get the permission href which matches the permission removed
        if (removedRevokedPermission.includes(getId(pp.permission))) {
          permissions.push({
            href: pp.href,
            permission: pp.permission
          });
        }
      }

      for (const granted of removedRevokedPermission) {
        for (const pp of permissions) {
          if (granted === getId(pp.permission)) {
            const payload = {
              identifier: counter,
              action: "DELETE",
              item: {
                href: `${pp.href}`,
                scope: `Vin:DealerId:${pd.dealerId}`,
                principal: `${baseURL}principals/id/${pd.user.principalId}`,
                permission: `${baseURL}atomicpermissions/id/${granted}`
              }
            };
            deletePrincipalPermissionsList.push(payload);
            counter++;
          }
        }
      }

      for (const pp of response.data.items) {
        // get the permission href which matches the permission removed
        if (removedGrantedPermission.includes(getId(pp.permission))) {
          permissions.push({
            href: pp.href,
            permission: pp.permission
          });
        }
      }

      for (const granted of removedGrantedPermission) {
        for (const pp of permissions) {
          if (granted === getId(pp.permission)) {
            const payload = {
              identifier: counter,
              action: "DELETE",
              item: {
                href: `${pp.href}`,
                scope: `Vin:DealerId:${pd.dealerId}`,
                principal: `${baseURL}principals/id/${pd.user.principalId}`,
                permission: `${baseURL}atomicpermissions/id/${granted}`
              }
            };
            deletePrincipalPermissionsList.push(payload);
            counter++;
          }
        }
      }

      return {
        deletePrincipalPermissionsList,
        counter
      };
    } catch (error) {
      return error;
    }
  }
}

export async function removePrincipalPermissionsAsync(
  jwt,
  principalDealersToUpdate
) {
  let counter = 1;
  const deletePrincipalPermissionsList = [];
  const baseURL = api.getPermissionsAPIUri();

  for (const pd of principalDealersToUpdate) {
    const princpalPermissions = pd.originalPermissions.granted.concat(
      pd.originalPermissions.revoked
    );

    const response = await api.getPrincipalPermissions(
      jwt,
      pd.user.principalId,
      pd.dealerId
    );
    for (const pp of response.data.items) {
      if (princpalPermissions.includes(getId(pp.permission))) {
        const payload = {
          identifier: counter,
          action: "DELETE",
          item: {
            href: `${pp.href}`,
            scope: `Vin:DealerId:${pd.dealerId}`,
            principal: `${baseURL}principals/id/${pd.user.principalId}`,
            permission: `${pp.permission}`
          }
        };
        deletePrincipalPermissionsList.push(payload);
        counter++;
      }
    }
  }
  return deletePrincipalPermissionsList;
}
