import React from "react";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import "./EnterprisePermissionsList.scss";
import { permission, role } from "../propTypes";
import PropTypes from "prop-types";
import { changedFromDefault } from "../utils/roles";
import { isEnabledForTooltip } from "../utils/permissionsTooltips";

const EnterprisePermissionsList = ({
  onPermissionChange,
  parentRole,
  permissions,
  selectedPermissions
}) => {
  return (
    <div className="enterprise__permissions__list">
      <h4>CRM and Enterprise Permission</h4>
      <p>
        Granting access to the below permissions will allow those data elements
        to be viewed/accessed by the user either within the enterprise customer
        tab or the customer dashboard if the user has CRM access.
      </p>

      {permissions &&
        permissions.map(p => {
          const htmlId = "-permission-" + p.id;
          const checked = selectedPermissions.includes(p.id);
          const toolTip = isEnabledForTooltip(p.displayName);

          return (
            <div key={htmlId} style={{ display: "flex" }}>
              <div style={{ width: "10px", marginRight: "5px" }}>
                {changedFromDefault(p.id, checked, parentRole) ? "*" : " "}
              </div>
              <input
                type="checkbox"
                onChange={onPermissionChange}
                id={htmlId}
                checked={checked}
                value={p.id}
              />{" "}
              <label htmlFor={htmlId} style={{ marginLeft: "5px" }}>
                {p.displayName}
              </label>
              {p.displayName === "Access/Enable Enterprise Customer" && (
                <Tooltip
                  htmlId={p.id + "__popover"}
                  tooltipContent={toolTip.content}
                  position="left"
                  className="popover-tooltip"
                >
                  <IconInfoOutline htmlId="InfoIcon" className="#InfoIcon" />
                </Tooltip>
              )}
            </div>
          );
        })}
    </div>
  );
};

EnterprisePermissionsList.propTypes = {
  onPermissionChange: PropTypes.func.isRequired,
  parentRole: role.isRequired,
  permissions: PropTypes.arrayOf(permission).isRequired,
  selectedPermissions: PropTypes.arrayOf(Number).isRequired
};

export default EnterprisePermissionsList;
