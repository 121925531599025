import React, { Fragment } from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError)
      return (
        <Fragment>
          <h1>Sorry, something went wrong.</h1>
          <p>The error has been logged. Please try reloading the page.</p>
          <p>
            If problem persists, please{" "}
            <a href="https://www.vinsolutions.com/customer-support/technical-support">
              contact VinSolutions Support
            </a>
            .
          </p>
        </Fragment>
      );
    return this.props.children;
  }
}

export default ErrorBoundary;
