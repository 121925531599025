import { isLocalOrDev, env, envEnum } from "../utils/environment";

// Parses IDS Token to get all the users claims
const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// Returns all claims for the user given the users
// access token.
export function getTokenClaims(token) {
  if (!token) return null;
  return parseJwt(token);
}

// Get Vin User Id from Access Token
export function getVinUserId(claims) {
  // If it's VinConnect the user id is surrogate_id
  if (claims.idp === "vinconnect") {
    return claims.surrogate_id;
  }

  // If it's bridge the user id is vin_user_id
  if (claims.idp === "bridge") {
    return claims.vin_user_id;
  }

  // If it's bridge the user id is vin_user_id
  if (claims.idp === "idsrv") {
    return claims.surrogate_id;
  }

  // We only allow for Vin or Bridge IDPs currently
  // so we will return null so they know that it does not exist.
  return null;
}

// Return whether user is Vin Employee through their claims.
// User Access Level 1 is a Vin Employee.
export function isVinEmployee(claims) {
  return parseInt(claims.useraccesslevel, 10) === 1;
}

export async function getIdsAccessToken(dispatch) {
  if (process.env.REACT_APP_USE_MOCK_API === "Y") {
    // Return a mock jwt if running against mock API
    return Promise.resolve({
      accessToken: "mock-token",
      expires: new Date().getTime() + 300000 // expire in 5 mins
    });
  }

  const response = await window.getJwt();

  // Did we get a valid response?
  // Throw an error if it's not local and we didn't get a response.
  if (!response || !response.accessToken || !response.expiresIn) {
    if (env === envEnum.local) {
      // Do nothing. Don't need JWT for localhost or local LAN.
    } else {
      dispatch(["ERROR_GETTING_JWT"]);
    }
  }

  // Create JWT for use to use to call API's
  const jwt = {
    accessToken: response.accessToken,
    expires: new Date().getTime() + response.expiresIn * 1000,
    selectedILMUserID: response.selectedILMUserID,
    claims: getTokenClaims(response.accessToken)
  };

  // If this is local or dev, lets replace the JWT with permissions Token
  if (isLocalOrDev) {
    jwt.accessToken = response.permissionsToken;
    jwt.expires = new Date().getTime() + response.permissionsExpiresIn * 1000;
  }

  // Refresh token 5 minutes before it expires
  // Must add check here since expires will be undefined if running local against mock data
  if (jwt.expires) {
    setTimeout(
      getIdsAccessToken,
      jwt.expires - new Date().getTime() - 5 * 60 * 1000,
      dispatch
    );
  }

  // Update state with JWT token
  dispatch(["UPDATE_JWT", jwt]);

  return jwt;
}
