import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { user, role, dealer } from "../propTypes";
import { permissionsAreCustomized } from "../utils/permissions";
import RoleDropdown from "./RoleDropdown";
import SimpleTable from "@cx/ui/SimpleTable";
import Badge from "@cx/ui/Badge";
import LoadingIndicator from "@cx/ui/LoadingIndicator/LoadingIndicator";
import "./AssignByDealer.scss";

const AssignByDealer = ({
  user,
  roles,
  allRoles,
  dealers: allDealers,
  onRoleChange,
  onCustomizeRolesClick,
  crmUser
}) => {
  // Array of dealer ids currently being saved
  const [isSaving, setIsSaving] = useState([]);

  function handleRoleChange(eventKey, dealer) {
    setIsSaving(state => [...state, dealer.id]);
    onRoleChange(user, eventKey, dealer).then(() =>
      // now remove dealerId from array since save is complete
      setIsSaving(state => state.filter(id => id !== dealer.id))
    );
  }

  // Memoize for performance so this is only recalculated when the relevant state changes
  const filteredUser = useMemo(() => {
    const filtered = {
      ...user,
      dealers: user.dealers.map(d => {
        return { ...d };
      })
    };

    filtered.dealers = user.dealers.filter(d => d.hasCrmAccess === crmUser);

    return filtered;
  }, [user, crmUser]);

  return (
    <React.Fragment>
      <SimpleTable>
        <caption className="sr-only">User Dealers</caption>
        <thead>
          <tr>
            <th scope="col">Dealer</th>
            <th scope="col">Role</th>
          </tr>
        </thead>
        <tbody>
          {filteredUser.dealers.map(dealer => {
            const customized = permissionsAreCustomized(
              dealer.permissions,
              allRoles.find(r => r.id === dealer.roleId)
            );

            const showLoading = isSaving.some(
              dealerId => dealerId === dealer.id
            );
            return (
              <tr key={dealer.id}>
                <th scope="row">
                  {allDealers.find(d => d.id === dealer.id).name}
                </th>
                <td>
                  <RoleDropdown
                    htmlId={"AssignByDealerRoleDropdown-" + dealer.id}
                    roles={roles}
                    allRoles={allRoles}
                    className="assignByDealerRoleDropdown"
                    aria-label={`Assign role for ${dealer.name}`}
                    disabled={showLoading}
                    selectedRole={roles.find(r => r.id === dealer.roleId)}
                    onChange={eventKey => handleRoleChange(eventKey, dealer)}
                    onCustomize={() => {
                      onCustomizeRolesClick(user, [dealer]);
                    }}
                  />
                  {customized && (
                    <Badge
                      id={
                        "AssignByDealerRoleDropdownCustomizedLabel-" + dealer.id
                      }
                    >
                      Customized
                    </Badge>
                  )}
                  {showLoading && (
                    <LoadingIndicator
                      floatToSide="left"
                      htmlId={"AssignByDealerLoadingIndicator" + dealer.id}
                      size="small"
                    />
                  )}
                </td>
                <td />
              </tr>
            );
          })}
        </tbody>
      </SimpleTable>
    </React.Fragment>
  );
};

AssignByDealer.propTypes = {
  allRoles: PropTypes.arrayOf(role).isRequired,
  crmUser: PropTypes.bool.isRequired,
  dealers: PropTypes.arrayOf(dealer).isRequired,
  onCustomizeRolesClick: PropTypes.func.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(role).isRequired,
  user: user.isRequired
};

export default AssignByDealer;
