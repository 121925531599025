import * as api from "../api";
import { getId } from "../utils/stringHelper";

const getPrincipalRoleId = async (jwt, principalId, dealerId) => {
  return await api.getPrincipalRoleAsync(jwt, principalId, dealerId);
};
// multi-user
export async function handleRoleForSelectedUsers(jwt, roleId, selectedUsers) {
  const items = [];
  let count = 1;
  const baseURL = api.getPermissionsAPIUri();
  for (const selectedUser of selectedUsers) {
    for (const dealer of selectedUser.dealers) {
      try {
        const hrefId = await getPrincipalRoleId(
          jwt,
          selectedUser.principalId,
          dealer.id
        );
        const data = await hrefId.data.items[0].href;
        const principalRoleId = getId(data);

        const payload = {
          identifier: count,
          action: "UPDATE",
          item: {
            href: `${baseURL}principalroles/id/${principalRoleId}`,
            scope: `Vin:DealerId:${dealer.id}`,
            principal: `${baseURL}principals/id/${selectedUser.principalId}`,
            role: `${baseURL}roles/id/${roleId}`
          }
        };
        items.push(payload);
        count++;
      } catch (error) {
        return error;
      }
    }
  }
  return items;
}
// single user
export async function handleRoleForSingleUser(jwt, user, roleId, crmUser) {
  try {
    let count = 1;
    const items = [];
    const baseURL = api.getPermissionsAPIUri();
    for (const userDealer of user.dealers) {
      if (userDealer.hasCrmAccess !== crmUser) {
        continue;
      }

      const hrefId = await getPrincipalRoleId(
        jwt,
        user.principalId,
        userDealer.id
      );
      const data = hrefId.data.items[0].href;
      const principalRoleId = getId(data);
      const payload = {
        identifier: count,
        action: "UPDATE",
        item: {
          href: `${baseURL}principalroles/id/${principalRoleId}`,
          scope: `Vin:DealerId:${userDealer.id}`,
          principal: `${baseURL}principals/id/${user.principalId}`,
          role: `${baseURL}roles/id/${roleId}`
        }
      };
      items.push(payload);
      count++;
    }
    return items;
  } catch (error) {
    return error;
  }
}
// update single dealer if user has access to mulitple dealers
export async function handleRoleUpdateForSingleDealer(
  jwt,
  user,
  dealer,
  roleId
) {
  const items = [];
  const baseURL = api.getPermissionsAPIUri();
  let count = 1;
  try {
    const response = await api.getPrincipalRoleAsync(
      jwt,
      user.principalId,
      dealer.id
    );
    const principalRoleId = getId(response.data.items[0].href);
    const payload = {
      identifier: count,
      action: "UPDATE",
      item: {
        href: `${baseURL}principalroles/id/${principalRoleId}`,
        scope: `Vin:DealerId:${dealer.id}`,
        principal: `${baseURL}principals/id/${user.principalId}`,
        role: `${baseURL}roles/id/${roleId}`
      }
    };
    items.push(payload);
    count++;
    return items;
  } catch (error) {
    return error;
  }
}
// role was changed from permissions modal
export async function handleRoleFromCustomized(jwt, user, roleId) {
  const items = [];
  let count = 1;
  const baseURL = api.getPermissionsAPIUri();

  try {
    for (const userRole of user) {
      const hrefId = await getPrincipalRoleId(
        jwt,
        userRole.user.principalId,
        userRole.dealerId
      );
      const data = await hrefId.data.items[0].href;
      const principalRoleId = getId(data);
      const payload = {
        identifier: count,
        action: "UPDATE",
        item: {
          href: `${baseURL}principalroles/id/${principalRoleId}`,
          scope: `Vin:DealerId:${userRole.dealerId}`,
          principal: `${baseURL}principals/id/${userRole.user.principalId}`,
          role: `${baseURL}roles/id/${roleId}`
        }
      };
      items.push(payload);
      count++;
    }
    return items;
  } catch (error) {
    return error;
  }
}
