import { env, envEnum } from "../../utils/environment";
import axios from "axios";

function getSettingsForDealer() {
  if (env === envEnum.dev || env === envEnum.local)
    return "https://swbwsvcdv-mts01.dev.vinstickers.com/Vin.DataAccess.EnterpriseCustomer/api/v1";
  if (env === envEnum.qa)
    return "https://wsvc.qa.vinsolutions.com/Vin.DataAccess.EnterpriseCustomer/api/v1";
  if (env === envEnum.staging)
    return "https://staging-wsvc.vinsolutions.com/Vin.DataAccess.EnterpriseCustomer/api/v1";
  return "https://wsvc.vinsolutions.com/Vin.DataAccess.EnterpriseCustomer/api/v1";
}

const baseURL = getSettingsForDealer();

const api = axios.create({
  baseURL,
  timeout: 150000,
  headers: {
    "X-CoxAuto-ReturnNulls": "true",
    Accept: "application/vnd.coxauto.v1+json"
  },
  withCredentials: false
});

export async function getEnterpriseSettingForDealer(jwt, dealers) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.post(`${baseURL}/GetFeatureEnablement`, {
    dealerIdList: [...dealers],
    setting: "EnterpriseCustomer"
  });
}
