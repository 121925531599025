import React, { Fragment } from "react";
import "./UserList.scss";
import Table from "@cx/ui/Table";
import PropTypes from "prop-types";
import { dealer } from "../propTypes";
/* eslint-disable react/display-name, react/no-multi-comp */

/** Pass components into cells */
export default function DealerList({ dealers, handleDealerSelect }) {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      className: "column-id"
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "column-name"
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleDealerSelect(selectedRows, selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record.checkBoxDisabled // Column configuration not to be checked
    }),
    selectedRowKeys: dealers.reduce(
      (out, d, index) => (d.selected ? out.concat(d.key) : out),
      []
    )
  };

  return (
    <Fragment>
      <div>
        <Table
          htmlId="TableRowComponents"
          data={dealers}
          columns={columns}
          rowSelection={rowSelection}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-even-row" : "table-odd-row"
          }
          sortableColumns
        />
      </div>
    </Fragment>
  );
}

DealerList.propTypes = {
  dealers: PropTypes.arrayOf(dealer).isRequired,
  handleDealerSelect: PropTypes.func.isRequired
};
