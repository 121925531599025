// Disabling prettier checks on this file for now. CX is running an old version of prettier that's conflicting with the ESLint version.
/* eslint-disable prettier/prettier */
import React, { useMemo, useEffect, useState, useReducer } from "react";
import UserList from "./UserList";
import * as api from "../api";
import MultiUserBar from "./MultiUserBar";
import ManageRoles from "./ManageRoles";
import FullPageModal from "@cx/ui/FullPageModal";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import ToastContainer, { toast } from "@cx/ui/Toast";
import PermissionModal from "./PermissionModal";
import AssignByDealer from "./AssignByDealer";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import "./App.scss";
import { appReducer, initialState } from "./appReducer";
import Filters from "./Filters";
import { getIdsAccessToken } from "../utils/idsHelper";
import {
  handleRoleForSelectedUsers,
  handleRoleForSingleUser,
  handleRoleFromCustomized,
  handleRoleUpdateForSingleDealer
} from "../utils/principalRolesHelper";
import ForbiddenPage from "./ForbiddenPage";
import useWindowSize from "react-use/lib/useWindowSize";
import { getId, getDealerId } from "../utils/stringHelper";
import IconWarning from "@cx/ui/Icons/IconWarning";
import DealersModal from "./DealersModal";
import * as userService from "../api/userService";
import * as enterpriseService from "../api/enterprise";
import * as cdService from "../api/carDashboard";
import { ecListIsToggledOn } from "../utils/toggleHelper";
import ConfirmDealerChangesModal from "./ConfirmDealerChangesModal";
import {
  handleUpdatePrincipalPermissionsAsync,
  removePrincipalPermissionsAsync
} from "../utils/principalPermissionsBulkHelper";
import { handleUpdateRolePermission } from "../utils/rolePermissionsHelper";
import { annotateRoles } from "../utils/roles";

function App() {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [showFindWarning, setShowFindWarning] = useState(false);

  // Column widths are calculated based on screen size.
  const { width, height } = useWindowSize();

  // Memoize for performance.
  const selectedUsers = useMemo(() => state.users.filter(u => u.selected), [
    state.users
  ]);

  // Declaring here to keep the dep array for the useMemo call below short.
  const {
    crmUser,
    dealers,
    userNameFilter,
    dealerFilter,
    roleFilter,
    users,
    bulkOperations,
    selectedUser,
    isEcSettingToggleEnabled
  } = state;

  // Memoize for performance so this is only recalculated when the relevant state changes
  const filteredUsers = useMemo(() => {
    let filtered = [...users];

    if (userNameFilter) {
      const filter = userNameFilter.toLowerCase();
      filtered = filtered.filter(
        u =>
          u.name.toLowerCase().includes(filter) ||
          u.userName.toLowerCase().includes(filter) ||
          (u.bridgeUserName && u.bridgeUserName.toLowerCase().includes(filter))
      );
    }

    if (dealerFilter.length > 0) {
      filtered = filtered.filter(u =>
        u.dealers.some(
          d =>
            dealerFilter.some(df => parseInt(df.value, 10) === d.id) &&
            d.hasCrmAccess === crmUser
        )
      );
    }

    if (roleFilter.length > 0) {
      filtered = filtered.filter(u =>
        u.dealers.some(
          d =>
            roleFilter.some(rf => parseInt(rf.value, 10) === d.roleId) &&
            d.hasCrmAccess === crmUser
        )
      );
    }

    return filtered;
  }, [userNameFilter, crmUser, dealerFilter, roleFilter, users]);

  // Memoize for performance so this is only recalculated when the relevant state changes
  const enterpriseUsers = useMemo(() => {
    let filteredEUsers = filteredUsers;

    filteredEUsers = filteredEUsers.filter(filteredEUser => {
      return !(
        filteredEUser.dealers.length === dealers.length &&
        filteredEUser.dealers.every(dealer => {
          return dealer.hasCrmAccess;
        })
      );
    });

    filteredEUsers = filteredEUsers.map(user => {
      return {
        ...user,
        dealers: user.dealers.filter(dealer => {
          return !dealer.hasCrmAccess;
        })
      };
    });

    return filteredEUsers;
  }, [filteredUsers, dealers]);

  // Memoize for performance so this is only recalculated when the relevant state changes
  const crmUsers = useMemo(() => {
    let filteredCRMUsers = filteredUsers;

    filteredCRMUsers = filteredCRMUsers.filter(filteredCRMUser => {
      return !(
        filteredCRMUser.dealers.length === dealers.length &&
        filteredCRMUser.dealers.every(dealer => {
          return !dealer.hasCrmAccess;
        })
      );
    });

    filteredCRMUsers = filteredCRMUsers.map(user => {
      return {
        ...user,
        dealers: user.dealers.filter(dealer => {
          return dealer.hasCrmAccess;
        })
      };
    });

    return filteredCRMUsers;
  }, [filteredUsers, dealers]);

  const filteredSelectedUsers = useMemo(() => {
    let filteredTypeUsers = selectedUsers.map(u => {
      return { ...u };
    });
    filteredTypeUsers = filteredTypeUsers.map(u => {
      u.dealers = u.dealers.filter(d => d.hasCrmAccess === crmUser);
      return u;
    });

    return filteredTypeUsers;
  }, [selectedUsers, crmUser]);

  useEffect(() => {
    document.addEventListener("keydown", captureFind);
    return () => document.removeEventListener("keydown", captureFind);
  });

  function captureFind(e) {
    // Mac uses cmd+f, so capture via metaKey per
    // https://stackoverflow.com/questions/3902635/how-does-one-capture-a-macs-command-key-via-javascript
    if ((e.ctrlKey || e.metaKey) && e.key === "f") setShowFindWarning(true);
  }

  useEffect(() => {
    if (!state.isInit) {
      initializeUI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.jwt, state.isInit]);

  const initializeUI = async () => {
    try {
      const selectedDealer = await cdService.getSelectedDealerId();

      const jwt = await getIdsAccessToken(dispatch);

      const response = await api.getEnterpriseUserList(jwt, selectedDealer);

      const isEcSettingToggleEnabled = await ecListIsToggledOn(jwt, response);

      const enterpriseUserList = response.data;

      enterpriseUserList.roles = annotateRoles(enterpriseUserList.roles);
      let enterpriseDealers = [];
      if (isEcSettingToggleEnabled) {
        const enterpriseResponse = await enterpriseService.getEnterpriseSettingForDealer(
          jwt,
          response.data.dealers.map(d => {
            return d.id;
          })
        );

        if (enterpriseResponse.status === 200) {
          enterpriseDealers = enterpriseResponse.data.dealerIdList;
        }
      }

      // return dealers from ent. policy that also is enabled for ec
      const ecDealers = enterpriseUserList.dealers.filter(x =>
        enterpriseDealers.includes(x.id)
      );

      dispatch([
        "LOAD_INITIAL_DATA",
        {
          enterpriseUserList,
          ecDealers,
          selectedDealer,
          isEcSettingToggleEnabled,
          jwt
        }
      ]);
    } catch (error) {
      handleInitializationError(error);
    }
  };

  const handleInitializationError = error => {
    if (error.response) {
      // Does this user have access?
      if (error.response.status === 403) {
        dispatch(["USER_NOT_AUTHORIZED"]);
      }

      // Check to make sure we got back an error from Permissions Service
      else if (error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors[0].message;
        dispatch(["ERROR_GETTING_USERS", errorMessage]);
      }

      // Check to make sure we got back an error from Permissions Service
      else {
        dispatch([
          "ERROR_GETTING_USERS",
          "Unknown error occured when getting users."
        ]);
      }
    } else {
      dispatch(["ERROR_GETTING_USERS", error.message]);
    }
  };

  const onRoleChange = async (user, roleId, dealer) => {
    try {
      const updateRole = await handleRoleUpdateForSingleDealer(
        state.jwt,
        user,
        dealer,
        roleId
      );
      await api.updatePrincipalRolesBulk(state.jwt, updateRole);
      dispatch(["SET_USER_ROLE", { user, roleId, dealerId: dealer.id }]);
      toast.success("Role saved.", { autoclose: 2000 });
    } catch (error) {
      dispatch(["API_ERROR", error.message]);
    }
  };

  const onCustomizeRolesClick = (user, dealers) =>
    dispatch(["ENABLE_CUSTOMIZE_ROLES", { user, dealers }]);

  const handleMultiUserApply = async role => {
    try {
      await handleUpdatePrincipalRole(filteredSelectedUsers, role.id);
      // we need to modify the body to pass to updatePP
      const principalDealersToUpdate = filteredSelectedUsers.map(user => {
        return user.dealers.map(dealer => {
          return {
            user,
            dealerId: dealer.id,
            originalPermissions: {
              granted: dealer.permissions.granted,
              revoked: dealer.permissions.revoked
            }
          };
        });
      });
      await updatePrincipalPermission(principalDealersToUpdate.flat());
      dispatch([
        "APPLY_PERMISSIONS_TO_MULTIPLE_USERS",
        { role, selectedUsers }
      ]);
      toast.success(
        `Role applied to ${selectedUsers.length} user${
          selectedUsers.length > 1 ? "s" : ""
        }.`
      );
    } catch (error) {
      dispatch([
        "API_ERROR",
        "Error updating principal roles for multiple users"
      ]);
    }
  };

  const closeManagePermissions = () => dispatch(["CLOSE_MANAGE_PERMISSIONS"]);

  const handleDeleteRoleClick = async role => {
    try {
      const permissionsToRemove = await handleUpdateRolePermission(
        state.jwt,
        state.roles,
        role
      );
      if (permissionsToRemove.length > 0)
        await api.updateRolePermissionsBulk(state.jwt, permissionsToRemove);

      await api.deleteRole(state.jwt, role);
      toast.success("Role deleted.");
      dispatch(["DELETE_ROLE", { role }]);
    } catch (error) {
      dispatch(["API_ERROR", error.message]);
    }
  };

  const handleSaveUserPermissions = async (
    selectedUsers,
    selectedDealers = [],
    parentRole,
    permissions,
    customRoleName,
    isCustomized
  ) => {
    let roleId = parentRole.id;

    try {
      if (customRoleName) {
        const newRole = await handleSaveRole({
          id: null,
          name: customRoleName,
          parentId: parentRole.id,
          dealerEditable: true,
          permissions,
          roleType: crmUser ? 1 : 2
        });
        roleId = newRole.id;
      }

      const principalDealersToUpdate = getPrincipalDealers(
        filteredSelectedUsers.length > 0
          ? filteredSelectedUsers
          : selectedUsers,
        selectedDealers,
        parentRole,
        permissions
      );
      const checkRoleUpdate = () => {
        for (const dealerToUpdate of principalDealersToUpdate) {
          for (const dealer of dealerToUpdate.user.dealers) {
            if (dealer.roleId !== roleId) {
              return true;
            }
          }
        }
        return false;
      };
      const updateRole = checkRoleUpdate(principalDealersToUpdate);

      if (updateRole) {
        await handleUpdatePrincipalRole(principalDealersToUpdate, roleId);
      }

      if (!customRoleName) {
        await updatePrincipalPermission(principalDealersToUpdate, roleId);
      }

      dispatch([
        "SAVE_PERMISSIONS",
        {
          customRoleName,
          isCustomized,
          selectedUsers,
          roleId,
          parentRole,
          selectedDealers,
          permissions
        }
      ]);
      toast.success("Permissions saved.");
    } catch (err) {
      dispatch(["API_ERROR", err.message]);
    }
  };

  const updatePrincipalPermission = async principalDealersToUpdate => {
    try {
      // remove permissions is user selected same princpalrole (Users List)
      if (!principalDealersToUpdate.some(x => x.customizedPermissions)) {
        const removePermissions = await removePrincipalPermissionsAsync(
          state.jwt,
          principalDealersToUpdate
        );

        if (removePermissions.length === 0) return;
        return await api.updatePrincipalPermissionsBulk(
          state.jwt,
          removePermissions
        );
      }

      const updatedPrincipalPermissions = await handleUpdatePrincipalPermissionsAsync(
        state.jwt,
        principalDealersToUpdate
      );

      if (updatedPrincipalPermissions.length === 0) return;
      return await api.updatePrincipalPermissionsBulk(
        state.jwt,
        updatedPrincipalPermissions
      );
    } catch (error) {
      dispatch(["API_ERROR", error.message]);
    }
  };

  const removePrincipalPermissions = async principalDealersToUpdate => {
    const dealersToUpdate = principalDealersToUpdate.filter(
      pd =>
        pd.originalPermissions.granted.length > 0 ||
        pd.originalPermissions.revoked.length > 0
    );

    if (dealersToUpdate.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(
      dealersToUpdate.map(async pd => {
        const response = await api.getPrincipalPermissions(
          state.jwt,
          pd.user.principalId,
          pd.dealerId
        );
        const principalPermissions = response.data.items.map(pp =>
          getId(pp.href)
        );
        return Promise.all(
          principalPermissions.map(pp => {
            return api.deletePrincipalPermissions(state.jwt, pp);
          })
        );
      })
    );
  };

  // Returns object with revoked/granted permissionIds
  const getCustomizedPermissions = (parentRole, selectedPermissions) => {
    const revoked = parentRole.permissions.reduce((acc, cur) => {
      if (!selectedPermissions.includes(cur)) acc.push(cur);
      return acc;
    }, []);

    const granted = selectedPermissions.reduce((acc, cur) => {
      if (!parentRole.permissions.includes(cur)) acc.push(cur);
      return acc;
    }, []);

    return { revoked, granted };
  };

  const getPrincipalDealers = (
    selectedUsers,
    selectedDealers,
    parentRole,
    permissions
  ) => {
    const principalDealersToUpdate = [];
    selectedUsers.forEach(user => {
      // So, if user is selected...
      if (selectedUsers.find(su => su.principalId === user.principalId)) {
        // set their role and additional permissions
        user.dealers.forEach(dealer => {
          const isMultiUserSave = selectedUsers.length > 1;
          const dealerIsSelected = selectedDealers
            .map(d => d.id)
            .includes(dealer.id);

          // If multi-user save, update permissions for all dealers.
          // If single user save, only update permissions if that dealer is passed into the list of selected dealers.
          if (isMultiUserSave || dealerIsSelected) {
            // set the new role and additional permissions for the dealer
            principalDealersToUpdate.push({
              user,
              dealerId: dealer.id,
              originalPermissions: {
                granted: dealer.permissions.granted,
                revoked: dealer.permissions.revoked
              },
              customizedPermissions: getCustomizedPermissions(
                parentRole,
                permissions
              )
            });
          }
        });
      }
    });
    return principalDealersToUpdate;
  };

  const handleUpdatePrincipalRole = async (user, roleId) => {
    // muliple user
    if (filteredSelectedUsers && filteredSelectedUsers.length > 0) {
      try {
        const handleMulitpleUsers = await handleRoleForSelectedUsers(
          state.jwt,
          roleId,
          filteredSelectedUsers
        );
        return await api.updatePrincipalRolesBulk(
          state.jwt,
          handleMulitpleUsers
        );
      } catch (error) {
        dispatch(["API_ERROR", error.message]);
      }
    }
    // single user
    if (user.dealers && user.dealers.length > 0) {
      try {
        const handleSingleUser = await handleRoleForSingleUser(
          state.jwt,
          user,
          roleId,
          crmUser
        );
        await api.updatePrincipalRolesBulk(state.jwt, handleSingleUser);
        dispatch(["SET_USER_ROLE", { user, roleId }]);
        return toast.success("Role saved.", { autoclose: 2000 });
      } catch (error) {
        dispatch(["API_ERROR", error.message]);
      }
    }
    // role changed from customized modal
    if (user && user.length > 0) {
      try {
        const handleRole = await handleRoleFromCustomized(
          state.jwt,
          user,
          roleId
        );
        await api.updatePrincipalRolesBulk(state.jwt, handleRole);
        dispatch(["SET_USER_ROLE", { user, roleId }]);
        return toast.success("Role saved.", { autoclose: 2000 });
      } catch (error) {
        dispatch(["API_ERROR", error.message]);
      }
    }
  };

  const handleSaveRole = async role => {
    if (role.id) {
      try {
        const updateRolePermission = await handleUpdateRolePermission(
          state.jwt,
          state.roles,
          role
        );
        if (updateRolePermission.length > 0)
          await api.updateRolePermissionsBulk(state.jwt, updateRolePermission);
        dispatch(["EDIT_ROLE", role]);
      } catch (error) {
        dispatch(["API_ERROR", { error: error.message }]);
      }
    } else {
      try {
        const response = await api.addRole(
          state.jwt,
          role,
          state.roles,
          state.orgId
        );
        role.id = getId(response.data.href);
        const permissionsToApply = await handleUpdateRolePermission(
          state.jwt,
          state.roles,
          role
        );

        if (permissionsToApply.length > 0)
          await api.updateRolePermissionsBulk(state.jwt, permissionsToApply);
        dispatch(["ADD_ROLE", role]);
        return role;
      } catch (error) {
        if (error.response) {
          // Does this role somehow already exist?
          if (error.response.status === 409) {
            return handleRoleSaveConflict(role, error);
          }
        }
        dispatch(["API_ERROR", error.message]);
      }
    }
  };

  const handleRoleSaveConflict = (role, error) => {
    role.id = getId(error.response.headers.location);
    return api
      .getRole(state.jwt, role.id)
      .then(response => {
        role.parentId = getId(response.data.parent);
        return api.getRolePermissions(state.jwt, role).then(response => {
          return addExistingRoleToState(response, role);
        });
      })
      .catch(error => {
        dispatch(["API_ERROR", error.message]);
      });
  };

  const addExistingRoleToState = (response, role) => {
    const rolePermissions = response.data.items;
    // Remove these from parent permissions
    // make sure this is an array of id instead
    // of array of rp objects
    const explicitRevoked = rolePermissions
      .filter(rp => !rp.grant)
      .map(rp => getId(rp.permission));
    // Add these on top of parent permissions
    // make sure this is an array of id instead
    // of an array of rp objects
    const explicitGranted = rolePermissions
      .filter(rp => rp.grant)
      .map(rp => getId(rp.permission));
    // Get Parent Role from existing roles. This is an assumption that
    // parent role will always lie within the list of roles. It's a safe
    // assumption as you can only ever create roles from a set list of roles when the UI starts.
    const parentRole = roles.find(r => r.id === role.parentId);
    // Add granted and parentRole permissions,
    // if revoked removes the permissions that's fine since
    // revoked overrides granted anyways.
    role.permissions = parentRole.permissions.concat(explicitGranted);
    // Remove explicit revokes from parent role
    role.permissions = role.permissions.filter(
      p => !explicitRevoked.includes(p)
    );
    dispatch(["ADD_ROLE", role]);
    toast.error("Role already exists. Could not create new role.");
    return role;
  };

  const createUserDealerBulkOperations = (users, dealers) => {
    const bulkOperations = [];
    dealers.forEach(d => {
      users.forEach(u => {
        const userHasCrmAccessToDealer = u.dealers.some(
          ud => ud.id === d.id && ud.hasCrmAccess
        );

        if (userHasCrmAccessToDealer && !d.selected) {
          bulkOperations.push({
            action: "delete",
            dealerId: d.id,
            userId: u.userId
          });
        } else if (d.selected && !userHasCrmAccessToDealer) {
          bulkOperations.push({
            action: "add",
            dealerId: d.id,
            userId: u.userId
          });
        }
      });
    });

    return bulkOperations;
  };

  const createPrincipalRoleBulkOperations = async (users, dealers) => {
    const bulkOperations = [];
    const baseURL = api.getPermissionsAPIUri();
    const enterpriseSalespersonRole = allRoles.find(
      r => r.name.replace(/\s/g, "") === "EnterpriseSalesperson"
    );

    let count = 0;
    for (const d of dealers) {
      for (const u of users) {
        const userDealer = u.dealers.find(ud => ud.id === d.id);

        // Two Scenarios
        // 1) Delete - Must have a role for a dealer and it must match the current crm type selected and dealer
        // 2) Add - Must not have a role for the dealer currently and it must be selected
        if (userDealer && userDealer.hasCrmAccess === crmUser && !d.selected) {
          const principalRoleResponse = await api.getPrincipalRoleAsync(
            state.jwt,
            u.principalId,
            d.id
          );

          const data = principalRoleResponse.data.items[0].href;
          const principalRoleId = getId(data);

          bulkOperations.push({
            identifier: count,
            action: "DELETE",
            item: {
              href: `${baseURL}principalroles/id/${principalRoleId}`,
              scope: `Vin:DealerId:${d.id}`,
              principal: `${baseURL}principals/id/${u.principalId}`,
              role: `${baseURL}roles/id/${enterpriseSalespersonRole.id}` // Default to salesperson
            }
          });
        } else if (d.selected && !userDealer) {
          bulkOperations.push({
            identifier: count,
            action: "ADD",
            item: {
              scope: `Vin:DealerId:${d.id}`,
              principal: `${baseURL}principals/id/${u.principalId}`,
              role: `${baseURL}roles/id/${enterpriseSalespersonRole.id}` // Default to salesperson
            }
          });
        }
        count++;
      }
    }
    return bulkOperations;
  };

  const displayConfirmBulkOperations = async (users, dealers) => {
    const bulkOperations = crmUser
      ? createUserDealerBulkOperations(users, dealers)
      : await createPrincipalRoleBulkOperations(users, dealers);

    dispatch(["SHOW_DEALERS_CONFIRM_MODAL", { bulkOperations }]);
  };

  const handleSaveUserDealersFromBulkOps = async bulkOps => {
    try {
      const response = await userService.addUsersToDealers(state.jwt, bulkOps);

      response.data.forEach(bulkOp => {
        const user = users.find(u => u.userId === bulkOp.UserId);
        const dealer = dealers.find(d => d.id === bulkOp.DealerId);

        if (bulkOp.Success) {
          const action =
            bulkOp.Action.toUpperCase() === "ADD" ? "added to" : "removed from";
          toast.success(`${user.userName} was ${action} ${dealer.name}`);
        } else {
          const action =
            bulkOp.Action.toUpperCase() === "ADD" ? "add" : "remove";
          const actionPlus =
            bulkOp.Action.toUpperCase() === "ADD" ? "to" : "from";
          toast.success(
            `Failed to ${action} ${user.userName} ${actionPlus} ${dealer.name}`
          );
        }
      });
      dispatch(["ASSIGN_USER_DEALERS", { response }]);
    } catch {
      dispatch(["API_ERROR", error.message]);
    }
  };

  const handleSavePrincipalRolesFromBulkOps = async bulkOps => {
    try {
      const response = await api.updatePrincipalRolesBulk(state.jwt, bulkOps);

      response.data.forEach(bulkOp => {
        if (bulkOp.action === "DELETE") {
          bulkOp.item = bulkOps.find(
            b => b.identifier === bulkOp.identifier
          ).item;
        }

        const user = users.find(
          u => u.principalId === getId(bulkOp.item.principal)
        );

        const dealer = dealers.find(
          d => d.id === getDealerId(bulkOp.item.scope)
        );

        if (bulkOp.statusCode === 201 || bulkOp.statusCode === 204) {
          const action =
            bulkOp.action.toUpperCase() === "ADD"
              ? "was granted non-crm access to"
              : "had non-crm removed from";
          toast.success(`${user.userName} ${action} ${dealer.name}`);
        } else {
          const action =
            bulkOp.Action.toUpperCase() === "ADD"
              ? "grant non-crm access"
              : "remove non-crm access";
          const actionPlus =
            bulkOp.Action.toUpperCase() === "ADD" ? "to" : "from";
          toast.error(
            `Failed to ${action} ${actionPlus} ${user.userName} for ${dealer.name}`
          );
        }
      });

      dispatch(["ASSIGN_USER_DEALERS", { response }]);
    } catch (error) {
      dispatch([
        "API_ERROR",
        error ? error.message : "Request to update users permissions failed."
      ]);
    }
  };

  const handleSaveUserDealers = async (users, dealers) => {
    try {
      const bulkOperations = createUserDealerBulkOperations(users, dealers);
      const response = await userService.addUsersToDealers(
        state.jwt,
        bulkOperations
      );
      response.data.forEach(bulkOp => {
        const user = users.find(u => u.userId === bulkOp.UserId);
        const dealer = dealers.find(d => d.id === bulkOp.DealerId);
        if (bulkOp.Success) {
          const action =
            bulkOp.Action.toUpperCase() === "ADD" ? "added to" : "removed from";
          toast.success(`${user.userName} was ${action} ${dealer.name}`);
        } else {
          const action =
            bulkOp.Action.toUpperCase() === "ADD" ? "add" : "remove";
          const actionPlus =
            bulkOp.Action.toUpperCase() === "ADD" ? "to" : "from";
          toast.error(
            `Failed to ${action} ${user.userName} ${actionPlus} ${dealer.name}`
          );
        }
      });

      dispatch(["ASSIGN_USER_DEALERS", { response }]);
    } catch {
      dispatch(["API_ERROR", error.message]);
    }
  };

  const handleSavePrincipalRoles = async (users, dealers) => {
    try {
      const bulkOperations = await createPrincipalRoleBulkOperations(
        users,
        dealers
      );

      await handleSavePrincipalRolesFromBulkOps(bulkOperations);
    } catch (error) {
      dispatch([
        "API_ERROR",
        error ? error.message : "Request to update users permissions failed."
      ]);
    }
  };

  const managedSelectedUsers = useMemo(() => {
    if (selectedUser) {
      return [
        users.find(user => {
          return user.userId === selectedUser.userId;
        })
      ];
    }

    return selectedUsers.map(user => {
      return users.find(u => {
        return u.userId === user.userId;
      });
    });
  }, [selectedUsers, selectedUser, users]);

  const {
    showMultiUserBar,
    roles,
    selectedDealers,
    assignByDealerPrincipalId,
    showAssignByDealer,
    isLoading,
    permissions,
    selectAll,
    error,
    forbidden,
    showDealersModal,
    showDealerConfirmDialog,
    allRoles
  } = state;
  if (error) {
    throw new Error(error);
  }

  if (forbidden) {
    return <ForbiddenPage />;
  }

  if (isLoading) {
    return (
      <div style={{ padding: "10% 0" }}>
        <LoadingIndicator size="large" htmlId="UserListLoadingIndicator" />
      </div>
    );
  }

  const initialEcUsers = users.filter(user => {
    return !(
      user.dealers.length === dealers.length &&
      user.dealers.every(dealer => {
        return dealer.hasCrmAccess;
      })
    );
  });

  const displayShowUserType =
    isEcSettingToggleEnabled &&
    (initialEcUsers.length > 0 || state.ecDealers.length > 0);

  // HACK: Goal is to make the UserList fill the remaining vertical space.
  // The height in state is the full viewport height. So to calculate desired UserList height,
  // we must account for the height of the header above the table.
  // If the multiuser bar is visible, the header is taller.
  function getUserListHeight() {
    let headerHeight;
    if (crmUser && !state.showMultiUserBar) {
      headerHeight = 275;
    }
    if (crmUser && state.showMultiUserBar) {
      headerHeight = 350;
    }
    if (!crmUser && !state.showMultiUserBar) {
      headerHeight = 300;
    }
    if (!crmUser && state.showMultiUserBar) {
      headerHeight = 373;
    }

    return height - headerHeight;
  }

  const assignByDealerUser = users.find(
    u => u.principalId === assignByDealerPrincipalId
  );

  const userListToDisplay = crmUser ? crmUsers : enterpriseUsers;

  const permissionsToDisplay = () => {
    return displayShowUserType
      ? permissions
      : permissions.filter(
          p => p.displayName !== "Access/Enable Enterprise Customer"
        );
  };
  const description = crmUser
    ? "Manage CRM Access and Permissions for your user list."
    : "Manage users ability to view data from dealerships they DO NOT have login access to. Access granted here will allow dealer data present in selected stores to be viewable from the Enterprise Customer tab on the Customer dashboard.";
  return (
    <main style={{ padding: 16, minWidth: 965 }}>
      <ToastContainer position="bottom-right" duration={3000} />
      <ModalDialog
        htmlId="find-warning-dialog"
        header={
          <ModalDialog.Title>
            Warning: Find Won&apos;t Search All Records
          </ModalDialog.Title>
        }
        show={showFindWarning}
        onHide={() => setShowFindWarning(false)}
      >
        <IconWarning className="warningIcon" />
        The browser find will only search through the users currently visible on
        this page. Use the filters at the top to search all users.
      </ModalDialog>
      {showAssignByDealer && (
        <ModalDialog
          htmlId="assign-by-dealer-dialog"
          show
          header={
            <ModalDialog.Title>{assignByDealerUser.name}</ModalDialog.Title>
          }
          onHide={() => dispatch(["CLOSE_ASSIGN_BY_DEALER"])}
        >
          <AssignByDealer
            roles={roles}
            allRoles={allRoles}
            dealers={dealers}
            user={assignByDealerUser}
            onRoleChange={onRoleChange}
            onCustomizeRolesClick={onCustomizeRolesClick}
            crmUser={crmUser}
          />
        </ModalDialog>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex" }}>
          <h1 className="header">
            Profile Manager - {crmUser ? "CRM User" : "Non CRM User"}
          </h1>

          {displayShowUserType && (
            <Button
              style={{ paddingBottom: "0" }}
              htmlId="toggle__user__type"
              buttonStyle="link"
              onClick={() => dispatch(["TOGGLE_USER_TYPE"])}
            >
              Switch user type
            </Button>
          )}
        </div>

        <div
          style={{
            display: "flex"
          }}
        >
          {!showMultiUserBar && (
            <Button
              htmlId="set-multi-user-button"
              onClick={() => dispatch(["ENABLE_MULTI_USER"])}
              style={{ marginRight: "20px" }}
            >
              Set Multi-user
            </Button>
          )}
          <Button
            htmlId="manage-roles-button"
            onClick={() => dispatch(["SHOW_MANAGE_ROLES"])}
          >
            Manage Roles
          </Button>
        </div>
      </div>

      <div style={{ clear: "both" }} />
      <div style={{ width: "50%" }}>{description}</div>
      <hr />
      <FullPageModal
        htmlId="manageRolesFullPageModal"
        show={state.showManageRoles}
        header={<FullPageModal.Title>Manage Roles</FullPageModal.Title>}
        breadcrumb={[
          {
            text: "Profile Manager",
            onClick: () => dispatch(["HIDE_MANAGE_ROLES"])
          },
          {
            text: "Manage Roles"
          }
        ]}
        onHide={() => dispatch(["HIDE_MANAGE_ROLES"])}
      >
        <ManageRoles
          roles={roles}
          permissions={permissionsToDisplay()}
          dispatch={dispatch}
          onDeleteRoleClick={handleDeleteRoleClick}
          onSaveRole={handleSaveRole}
          {...{
            users,
            crmUser,
            displayShowUserType
          }}
        />
      </FullPageModal>
      {showMultiUserBar && (
        <MultiUserBar
          {...{
            allRoles,
            filteredSelectedUsers,
            isLoading,
            roles
          }}
          onClickAddToDealer={() => dispatch(["SHOW_DEALERS_MODAL"], {})}
          onClickCancel={() => dispatch(["DISABLE_MULTI_USER"])}
          onClickRoleCustomize={() =>
            dispatch(["SHOW_CUSTOMIZE_ROLE", { users: filteredSelectedUsers }])
          }
          onSubmit={handleMultiUserApply}
        />
      )}
      <Filters
        numFilteredUsers={userListToDisplay.length}
        userNameFilter={userNameFilter}
        dealerFilter={dealerFilter}
        roleFilter={roleFilter}
        dispatch={dispatch}
        dealers={dealers}
        roles={roles}
      />

      <UserList
        crmView={crmUser}
        roles={roles}
        allRoles={allRoles}
        users={userListToDisplay}
        dealers={dealers}
        selectAll={selectAll}
        dispatch={dispatch}
        multiUser={showMultiUserBar}
        disableFields={showMultiUserBar}
        updateUserRole={handleUpdatePrincipalRole}
        // HACK: width is the full screen width. Reduce to avoid creating a horizontal scrollbar on Windows.
        width={width - 35}
        height={getUserListHeight()}
        removePrincipalPermissions={removePrincipalPermissions}
        updatePrincipalPermission={updatePrincipalPermission}
      />

      {state.showManagePermissions && (
        <PermissionModal
          allDealers={dealers}
          selectedDealers={selectedDealers}
          closeManagePermissions={closeManagePermissions}
          onClickCancel={closeManagePermissions}
          onClickSave={handleSaveUserPermissions}
          users={
            state.managePermissionsMultipleUsers
              ? selectedUsers
              : [selectedUser]
          }
          roles={roles}
          allRoles={allRoles}
          permissions={permissionsToDisplay()}
        />
      )}
      {showDealersModal && (
        <DealersModal
          allDealers={dealers}
          onClickCancel={() => dispatch(["HIDE_DEALERS_MODAL", {}])}
          onClickSave={
            crmUser ? handleSaveUserDealers : handleSavePrincipalRoles
          }
          displayConfirmBulkOperations={displayConfirmBulkOperations}
          users={managedSelectedUsers}
          crmUser={crmUser}
        />
      )}
      {showDealerConfirmDialog && (
        <ConfirmDealerChangesModal
          onClickCancel={() => dispatch(["HIDE_DEALERS_CONFIRM_MODAL", {}])}
          onClickSave={
            crmUser
              ? handleSaveUserDealersFromBulkOps
              : handleSavePrincipalRolesFromBulkOps
          }
          bulkOperations={bulkOperations}
          dealers={dealers}
          users={managedSelectedUsers}
          crmUser={crmUser}
        />
      )}
    </main>
  );
}

export default App;
