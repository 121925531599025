import React, {
  Fragment,
  useReducer,
  useState,
  useMemo,
  useEffect
} from "react";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import Alert from "@cx/ui/Alert";
import {
  dealersModalReducer,
  initialState
} from "./Reducers/dealersModalReducer";
import DealerList from "./DealerList";
import PropTypes from "prop-types";
import { user, dealer } from "../propTypes";

function DealersModal({
  allDealers,
  users,
  onClickCancel,
  onClickSave,
  displayConfirmBulkOperations,
  crmUser
}) {
  const [state, dispatch] = useReducer(dealersModalReducer, initialState);
  const [dealerFilter, setDealerFilter] = useState("");
  const { dealers, isSaving, showMultiDealerWarning } = state;

  useEffect(() => {
    dispatch([
      "LOAD_INITIAL_DATA",
      { users, dealers: allDealers, crmView: crmUser, isDisabled: true }
    ]);
  }, [users, allDealers, crmUser]);

  const onClickReset = () => {
    dispatch([
      "RESET_TO_DEFAULT",
      { users, dealers: allDealers, crmView: crmUser, isDisabled: true }
    ]);
  };

  // have dealerlist call a function when selection is made

  // Memoize for performance so this is only recalculated when the relevant state changes
  const filteredDealers = useMemo(() => {
    let filtered = dealers;

    if (users.length === 1) {
      filtered = filtered.filter(dealer => {
        const userDealer = users[0].dealers.find(ud => {
          return ud.id === dealer.id;
        });

        if (userDealer && !crmUser) {
          return userDealer.hasCrmAccess === crmUser;
        }

        return true;
      });
    } else if (users.length > 1 && crmUser === false) {
      filtered = filtered.filter(dealer => {
        return !users.some(user =>
          user.dealers.some(
            ud => ud.id === dealer.id && ud.hasCrmAccess !== crmUser
          )
        );
      });
    }

    if (dealerFilter) {
      filtered = filtered.filter(
        dealer =>
          dealer &&
          (dealer.id === parseInt(dealerFilter, 10) ||
            dealer.name.toLowerCase().includes(dealerFilter.toLowerCase()))
      );
    }
    return filtered;
  }, [dealers, dealerFilter, crmUser, users]);

  const handleSaveClick = () => {
    dispatch(["SET_LOADING_INDICATOR", {}]);

    if (users.length > 1) {
      displayConfirmBulkOperations(users, dealers);
    } else {
      onClickSave(users, dealers);
    }
  };

  const handleDealerSelect = (selectedRows, selectedRowKeys) => {
    dispatch([
      "SELECT_DEALER",
      { selectedRows, selectedRowKeys, filteredDealers }
    ]);
  };

  return (
    <Fragment>
      <ModalDialog
        htmlId="DealersModal"
        className="dealersmodal"
        show
        onHide={onClickCancel}
        header={<ModalDialog.Title>Select Dealers</ModalDialog.Title>}
        footer={
          <React.Fragment>
            <SubmitButton
              htmlId="dealersmodal-reset"
              buttonStyle="link"
              type="submit"
              onClick={onClickReset}
              className="dealersmodal__reset"
              style={{ float: "left" }}
            >
              Reset to Default
            </SubmitButton>
            <SubmitButton
              htmlId="dealersmodal-cancel"
              buttonStyle="default"
              disabled={isSaving}
              type="submit"
              onClick={onClickCancel}
            >
              Cancel
            </SubmitButton>
            <SubmitButton
              htmlId="dealersmodal-save"
              buttonStyle="primary"
              className="btn--loader"
              type="submit"
              disabled={state.isDisabled}
              onClick={() => handleSaveClick(users, dealers)}
            >
              {isSaving ? (
                <Fragment>
                  <LoadingIndicator
                    htmlId="DealersModalSaveLoadingIndicator"
                    size="small"
                    color="white"
                  />
                  Saving
                </Fragment>
              ) : (
                "Save"
              )}
            </SubmitButton>
          </React.Fragment>
        }
      >
        <Fragment>
          <h3 className="dealersmodal__username">
            {users.length > 1
              ? users.length + " users selected"
              : users[0].name}
          </h3>
        </Fragment>

        <Fragment>
          <TextInput
            htmlId="dealersmodal-dealer-filter"
            label="Filter Dealers"
            layout="horizontal"
            maxLength={50}
            onChange={event => {
              setDealerFilter(event.target.value);
            }}
            value={dealerFilter}
            name="dealerFilter"
          >
            <Button
              htmlId="manageDealersModalClearFilterButton"
              onClick={() => setDealerFilter("")}
            >
              Clear Filter
            </Button>
          </TextInput>
          {showMultiDealerWarning && (
            <Alert htmlId="multiDealerWarning" type="warning">
              <strong>
                The selected users have different dealers. If a user already has
                access to a dealer that is checked, it will not be added again.
                If a user has access to a dealer that is unchecked, we will
                remove the user&apos;s access to that dealer.
              </strong>
            </Alert>
          )}
          <br />
          <div className="clearfix" />
          <DealerList
            dealers={filteredDealers}
            {...{
              dispatch,
              handleDealerSelect
            }}
          />
          <hr />
        </Fragment>
      </ModalDialog>
    </Fragment>
  );
}

DealersModal.propTypes = {
  allDealers: PropTypes.arrayOf(dealer).isRequired,
  crmUser: PropTypes.bool.isRequired,
  displayConfirmBulkOperations: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(user).isRequired
};

export default DealersModal;
