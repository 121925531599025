import React from "react";
import PropTypes from "prop-types";
import { permission } from "../propTypes";
import PermissionCheckboxList from "./PermissionCheckboxList";
import { getPermissionsForRole } from "../utils/rolePermissions";

class PermissionCategories extends React.Component {
  getPermissionsSortedByCategory(permissions) {
    // Sort is an in place algorithm. So clone array to avoid mutating with sort.
    const sortedPermissions = [...permissions];
    sortedPermissions.sort(this.sortByCategory);
    return sortedPermissions;
  }

  sortByCategory(a, b) {
    return a.category.localeCompare(b.category);
  }

  renderPermissionCheckboxList = (heading, permissions) => (
    <PermissionCheckboxList
      key={heading}
      heading={heading}
      onChange={this.props.onPermissionChange}
      permissions={permissions}
      parentRole={this.props.parentRole}
      selectedPermissions={this.props.selectedPermissions}
    />
  );

  render() {
    if (this.props.permissions.length === 0) return null;

    // Hack: This call can be removed and this.props.permissions can be referenced instead below once we support removing permissions from roles.
    const permissions = getPermissionsForRole(
      this.props.parentRole,
      this.props.permissions
    );

    // If no matching permissions are found for the selected role, return null. This can occur when a filter has been entered that doesn't return any matches.
    if (permissions.length === 0) return null;

    // Now loop through permissions and collect an array of checkboxLists, one for each category
    const checkboxLists = [];

    // Disable displaying the popular category for now. It's unnecessary with such a small list.
    // checkboxLists.push(
    //   this.renderPermissionCheckboxList(
    //     "Popular",
    //     permissions.filter(p => p.isPopular)
    //   )
    // );

    const sortedPermissions = this.getPermissionsSortedByCategory(permissions);
    let permissionsToRender = [];
    let lastCategory = sortedPermissions[0].category;
    sortedPermissions.forEach(permission => {
      if (lastCategory === permission.category) {
        permissionsToRender.push(permission);
      } else {
        checkboxLists.push(
          this.renderPermissionCheckboxList(lastCategory, permissionsToRender)
        );
        // Start a new list to render
        permissionsToRender = [permission];
      }
      lastCategory = permission.category;
    });
    // Render the final category
    if (permissionsToRender.length > 0) {
      checkboxLists.push(
        this.renderPermissionCheckboxList(lastCategory, permissionsToRender)
      );
    }
    return checkboxLists;
  }
}

PermissionCategories.propTypes = {
  onPermissionChange: PropTypes.func.isRequired,
  parentRole: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(permission).isRequired,
  selectedPermissions: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default PermissionCategories;
