export const dealers = [
  {
    id: 1,
    name: "Cox Motors Demo"
  },
  {
    id: 6210,
    name: "VinCare Training"
  },
  {
    id: 3126,
    name: "Ferman Chrysler Jeep Dodge RAM of New Port Richey"
  },
  {
    id: 3127,
    name: "Ferman Chevrolet Volvo of Tarpon Springs"
  },
  {
    id: 3128,
    name: "Ferman BMW / MINI of Tampa Bay"
  },
  {
    id: 3131,
    name: "Ferman Chrysler Jeep Dodge & Buick GMC "
  },
  {
    id: 11,
    name: "Gary Crossley Ford"
  },
  {
    id: 15331,
    name: "Lex Mac Motors"
  },
  {
    id: 4379,
    name: "Alabama Taxes"
  },
  {
    id: 4380,
    name: "Alaska Taxes"
  },
  {
    id: 4381,
    name: "Arizona Taxes"
  },
  {
    id: 4382,
    name: "Arkansas Taxes"
  },
  {
    id: 4383,
    name: "California Taxes"
  },
  {
    id: 4384,
    name: "Colorado Taxes"
  },
  {
    id: 4385,
    name: "Load Testing QA"
  },
  {
    id: 4386,
    name: "Delaware Taxes"
  },
  {
    id: 4387,
    name: "Florida Taxes"
  },
  {
    id: 4388,
    name: "Georgia Taxes"
  }
];

export const orgId = 16670;

export const loggedInUser = {
  id: 1
};

// use this only for unit test
export const usersTest = [
  {
    principalId: 49687,
    name: "Mobile Salesperson",
    userName: "MobileSalesPerson",
    dealers: [
      {
        id: 6210,
        roleId: 2,
        permissions: {
          granted: [21, 20],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 6211,
        roleId: 2,
        permissions: {
          granted: [21, 20],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 14050,
    userId: 9,
    name: "Dave Watson",
    userName: "Demo",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 4379,
        roleId: 18107,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: false
      }
    ]
  },
  {
    principalId: 18911,
    userId: 5724,
    name: "Jeff Wolfer",
    userName: "jwolfer",
    dealers: [
      {
        id: 1,
        roleId: 147,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 29861,
    userId: 9700,
    name: "Generic Sales",
    userName: "gensales",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [56, 75, 53, 36],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 4379,
        roleId: 2,
        permissions: {
          granted: [56, 75, 53, 36],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  }
];

export const users = [
  {
    principalId: 14050,
    userId: 9,
    name: "Dave Watson",
    userName: "Demo",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 4379,
        roleId: 18107,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: false
      }
    ]
  },
  {
    principalId: 18911,
    userId: 5724,
    name: "Jeff Wolfer",
    userName: "jwolfer",
    dealers: [
      {
        id: 1,
        roleId: 147,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 29861,
    userId: 9700,
    name: "Generic Sales",
    userName: "gensales",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [56, 75, 53, 36],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 4379,
        roleId: 2,
        permissions: {
          granted: [56, 75, 53, 36],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 29864,
    userId: 9701,
    name: "Generic Manager",
    userName: "genmanager",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 29867,
    userId: 9702,
    name: "Generic Administrator",
    userName: "genadmin",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [],
          revoked: [68]
        },
        hasCrmAccess: true
      },
      {
        id: 6210,
        roleId: 1,
        permissions: {
          granted: [],
          revoked: [68]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 31284,
    userId: 10331,
    name: "Travis Douglas",
    userName: "tdouglas",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 41315,
    userId: 13315,
    name: "Donna Receptionist",
    userName: "dhstearns",
    dealers: [
      {
        id: 1,
        roleId: 4,
        permissions: {
          granted: [38, 36, 39, 40, 41, 44, 45, 46, 47, 48],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 6210,
        roleId: 4,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 42942,
    userId: 14045,
    name: "David Tinsley",
    userName: "seo",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 61274,
    userId: 19589,
    name: "Jayson McClary",
    userName: "Jmcclary",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      },
      {
        id: 6210,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 96141,
    userId: 28297,
    name: "Mary Smith",
    userName: "mssmith",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [35, 54],
          revoked: [75, 69, 68]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 98533,
    userId: 29191,
    name: "Service Tech",
    userName: "ServiceTech2",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: [75]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 98894,
    userId: 29338,
    name: "Vin Camera",
    userName: "vincameratester",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [],
          revoked: [75]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 99688,
    userId: 29623,
    name: "Texting Test",
    userName: "twilio",
    dealers: [
      {
        id: 1,
        roleId: 87,
        permissions: {
          granted: [],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 100029,
    userId: 29668,
    name: "Test Manager",
    userName: "testmanager",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: [75]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 103614,
    userId: 30227,
    name: "Elizabeth Barerra",
    userName: "ebarrera1014",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [101, 69, 68, 53, 46, 39],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 183322,
    userId: 30287,
    name: "Mark Dell",
    userName: "MDell1",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 104535,
    userId: 30342,
    name: "Operator Receptionist",
    userName: "Operator1",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [34, 36, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 108970,
    userId: 31752,
    name: "test test",
    userName: "thisisatest1234test",
    dealers: [
      {
        id: 1,
        roleId: 107,
        permissions: {
          granted: [39],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 109501,
    userId: 31920,
    name: "Brendan Shea",
    userName: "Brendanmshea",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [51, 39, 41],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 109935,
    userId: 32067,
    name: "TONY CARGUY",
    userName: "tonycarguy",
    dealers: [
      {
        id: 1,
        roleId: 2561,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 112977,
    userId: 32699,
    name: "Johnny number 5",
    userName: "johnny5",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [47, 46, 45, 42, 41, 40, 39, 38, 48, 36],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 119688,
    userId: 34698,
    name: "Todays Deal",
    userName: "dealTest",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [54, 34, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 120254,
    userId: 34862,
    name: "Diepholz Test",
    userName: "deiptest1",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [54, 39, 51, 41],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 129058,
    userId: 37480,
    name: "Matt  Dawdy",
    userName: "mdawdysales",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [54, 34, 36],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 130098,
    userId: 37552,
    name: "View  Standard Forms",
    userName: "viewforms",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [54, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 131669,
    userId: 38130,
    name: "Frank The Tank",
    userName: "test12345789",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [20, 34, 54],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 131933,
    userId: 38235,
    name: "Sally  Solution",
    userName: "sallys",
    dealers: [
      {
        id: 1,
        roleId: 107,
        permissions: {
          granted: [34, 39, 54],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 144938,
    userId: 40130,
    name: "David Smtih",
    userName: "demod",
    dealers: [
      {
        id: 1,
        roleId: 4,
        permissions: {
          granted: [54, 34, 51],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 152313,
    userId: 41247,
    name: "Topher Birth",
    userName: "tbirth",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [34, 54, 39],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 156188,
    userId: 41976,
    name: "Kiersten Skov",
    userName: "Kiersten10",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [40, 54, 51, 42, 34],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 161614,
    userId: 43356,
    name: "Tom Rabiola",
    userName: "Rabiola",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 164643,
    userId: 44176,
    name: "Kay Tester",
    userName: "ktester1",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [34, 51],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 166781,
    userId: 45011,
    name: "Testing System",
    userName: "martintest",
    dealers: [
      {
        id: 1,
        roleId: 4,
        permissions: {
          granted: [51],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 169741,
    userId: 45752,
    name: "Kevin Reilly",
    userName: "kpreilly",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [35, 51, 34, 54],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 674514,
    userId: 819063,
    name: "User6-B1islinked User6-B1islinked",
    userName: "User6-B1islinked",
    bridgeUserName: "uuser6",
    platformId: "b3f536f7-8fb6-4500-bc18-5a68d19d4770",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 674517,
    userId: 819066,
    name: "User8-B2islinked User8-B2islinked",
    userName: "User8-B2islinked",
    bridgeUserName: "uuser18",
    platformId: "91aaac8b-55d7-43d5-ba92-9e9a73a14610",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 674510,
    userId: 819059,
    name: "User4-B1islinked User4-B1islinked",
    userName: "User8-B2islinked",
    bridgeUserName: "uuser4",
    platformId: "93011be0-f4e3-4e39-b696-aeda7d9a6e37",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [34],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 174817,
    userId: 47000,
    name: "Jay Hilley",
    userName: "jhilley",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [35, 54, 51],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 177817,
    userId: 47804,
    name: "Ken Pless",
    userName: "KPLESS",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [51, 54, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 185045,
    userId: 49520,
    name: "Kevin Brodee",
    userName: "kbrodee01",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [],
          revoked: [75]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 186082,
    userId: 49525,
    name: "Robi B",
    userName: "Robib",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [34, 35, 51, 54],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 188492,
    userId: 50251,
    name: "John Begy",
    userName: "VSjbegy",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 193309,
    userId: 51691,
    name: "test test",
    userName: "rtest",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [],
          revoked: [69, 68]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 194891,
    userId: 52072,
    name: "Manager Manager",
    userName: "MManager1",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [35, 51, 54],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 194894,
    userId: 52073,
    name: "Sales Sales",
    userName: "SSales1",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [54, 51, 34],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 194901,
    userId: 52075,
    name: "Manager Manager",
    userName: "MManager10",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [51, 54, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 194903,
    userId: 52076,
    name: "CJ Baggzz",
    userName: "SSales10",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [35, 51, 34],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 197691,
    userId: 52278,
    name: "Ed M",
    userName: "Ed123456",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [51, 54, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 221408,
    userId: 56265,
    name: "Timmy Testerson",
    userName: "ttesterson1",
    dealers: [
      {
        id: 1,
        roleId: 4,
        permissions: {
          granted: [34, 51, 54],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 221660,
    userId: 56384,
    name: "k smit",
    userName: "ksmit",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [34, 51, 54, 36],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 224622,
    userId: 57135,
    name: "sales rep",
    userName: "salesrep",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [51, 54, 34],
          revoked: [74]
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 234909,
    userId: 59323,
    name: "Test Test",
    userName: "test111",
    dealers: [
      {
        id: 1,
        roleId: 1,
        permissions: {
          granted: [34, 51, 54, 35],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 234923,
    userId: 59327,
    name: "Steve Test",
    userName: "stevetest",
    dealers: [
      {
        id: 1,
        roleId: 4,
        permissions: {
          granted: [51],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 234939,
    userId: 59335,
    name: "Steve Test",
    userName: "stevetest1",
    dealers: [
      {
        id: 1,
        roleId: 4,
        permissions: {
          granted: [34, 54, 51],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 234981,
    userId: 59366,
    name: "Test Ext 146",
    userName: "Test146",
    dealers: [
      {
        id: 1,
        roleId: 3,
        permissions: {
          granted: [35, 54, 51],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  },
  {
    principalId: 238302,
    userId: 60381,
    name: "LeRoy Jenkins",
    userName: "LJenkins1",
    dealers: [
      {
        id: 1,
        roleId: 2,
        permissions: {
          granted: [51, 54, 34],
          revoked: []
        },
        hasCrmAccess: true
      }
    ]
  }
];
export const ecRoles = [];

export const roles = [
  {
    id: 1,
    name: "Admin",
    parentId: null,
    dealerEditable: false,
    permissions: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      20,
      21,
      36,
      38,
      39,
      40,
      41,
      42,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      53,
      54,
      55,
      56,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833,
      29233,
      31116
    ]
  },
  {
    id: 2,
    name: "Salesperson",
    parentId: null,
    dealerEditable: false,
    permissions: [2, 3, 4, 8, 9, 11, 12, 13, 14, 15, 18, 74, 833]
  },
  {
    id: 3,
    name: "Manager",
    parentId: null,
    dealerEditable: false,
    permissions: [
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      20,
      21,
      36,
      38,
      39,
      40,
      41,
      42,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      55,
      56,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833,
      29233,
      31116
    ]
  },
  {
    id: 4,
    name: "None",
    parentId: null,
    dealerEditable: false,
    permissions: [19]
  },
  {
    id: 86,
    name: "Custom Test 2",
    parentId: 2,
    dealerEditable: true,
    permissions: [
      2,
      3,
      4,
      8,
      9,
      11,
      12,
      13,
      14,
      15,
      18,
      53,
      54,
      68,
      69,
      74,
      100,
      833
    ]
  },
  {
    id: 87,
    name: "MultiUserSync",
    parentId: 2,
    dealerEditable: true,
    permissions: [2, 3, 4, 8, 9, 11, 12, 13, 14, 15, 18, 74, 833]
  },
  {
    id: 107,
    name: "Salesperson custom 7",
    parentId: 2,
    dealerEditable: true,
    permissions: [2, 3, 4, 8, 9, 11, 12, 13, 14, 15, 18, 74, 833]
  },
  {
    id: 116,
    name: "Custom Sales All",
    parentId: 2,
    dealerEditable: true,
    permissions: [2, 3, 4, 8, 9, 11, 12, 13, 14, 15, 18, 74, 833]
  },
  {
    id: 119,
    name: "GA",
    parentId: 1,
    dealerEditable: true,
    permissions: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      20,
      21,
      36,
      38,
      39,
      40,
      41,
      42,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      53,
      54,
      55,
      56,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833
    ]
  },
  {
    id: 138,
    name: "Custom Admin",
    parentId: 1,
    dealerEditable: true,
    permissions: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      20,
      21,
      36,
      38,
      39,
      40,
      41,
      42,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      54,
      55,
      56,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833
    ]
  },
  {
    id: 140,
    name: "View Vehicle Cost",
    parentId: 2,
    dealerEditable: true,
    permissions: [
      2,
      3,
      4,
      8,
      9,
      11,
      12,
      13,
      14,
      15,
      18,
      36,
      47,
      56,
      73,
      74,
      833
    ]
  },
  {
    id: 144,
    name: "Modify email templates",
    parentId: 2,
    dealerEditable: true,
    permissions: [2, 3, 4, 8, 9, 11, 12, 13, 14, 15, 18, 36, 53, 56, 75, 833]
  },
  {
    id: 145,
    name: "No cost --no desking",
    parentId: 3,
    dealerEditable: true,
    permissions: [
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      20,
      21,
      38,
      39,
      40,
      41,
      42,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      55,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833
    ]
  },
  {
    id: 146,
    name: "Test Custom Role",
    parentId: 3,
    dealerEditable: true,
    permissions: [
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      20,
      21,
      38,
      39,
      40,
      41,
      42,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      55,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833
    ]
  },
  {
    id: 147,
    name: "Another Test Role",
    parentId: 3,
    dealerEditable: true,
    permissions: [
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      20,
      21,
      38,
      39,
      40,
      41,
      42,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      55,
      57,
      68,
      69,
      71,
      72,
      73,
      74,
      75,
      100,
      101,
      833
    ]
  },
  {
    id: 2561,
    name: "BD Agent",
    parentId: 3,
    dealerEditable: true,
    permissions: [
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      20,
      21,
      38,
      39,
      40,
      41,
      42,
      45,
      46,
      47,
      48,
      49,
      50,
      52,
      55,
      57,
      68,
      69,
      71,
      72,
      74,
      75,
      100,
      101,
      833
    ]
  },
  {
    id: 18107,
    name: "Enterprise Salesperson",
    parentId: null,
    dealerEditable: false,
    permissions: [74, 844]
  },
  {
    id: 18109,
    name: "Enterprise Manager",
    parentId: null,
    dealerEditable: false,
    permissions: [74, 47, 5, 34, 73, 844]
  },
  {
    id: 18105,
    name: "Custom Enterprise Salesperson",
    parentId: 18107,
    dealerEditable: true,
    permissions: [74]
  }
];

export const permissions = [
  {
    id: 1,
    displayName: "Manage EC Policies",
    isPopular: false,
    category: ""
  },
  {
    id: 2,
    displayName: "View Activities",
    isPopular: false,
    category: ""
  },
  {
    id: 3,
    displayName: "View Appointments",
    isPopular: false,
    category: ""
  },
  {
    id: 4,
    displayName: "View Appraisals",
    isPopular: false,
    category: ""
  },
  {
    id: 5,
    displayName: "View Appraisals (ACV)",
    isPopular: false,
    category: ""
  },
  {
    id: 6,
    displayName: "View Appraisals (Payoff)",
    isPopular: false,
    category: ""
  },
  {
    id: 7,
    displayName: "View Appraisals (Value)",
    isPopular: false,
    category: ""
  },
  {
    id: 8,
    displayName: "View Deals",
    isPopular: false,
    category: ""
  },
  {
    id: 9,
    displayName: "View Master Record (EC)",
    isPopular: false,
    category: ""
  },
  {
    id: 10,
    displayName: "View Master Record (Gross)",
    isPopular: false,
    category: ""
  },
  {
    id: 11,
    displayName: "View Opportunities",
    isPopular: false,
    category: ""
  },
  {
    id: 12,
    displayName: "View Value",
    isPopular: false,
    category: ""
  },
  {
    id: 13,
    displayName: "View Vehicles (Vehicle)",
    isPopular: false,
    category: ""
  },
  {
    id: 14,
    displayName: "View Vehicles (Equity)",
    isPopular: false,
    category: ""
  },
  {
    id: 15,
    displayName: "View Vehicles (Value)",
    isPopular: false,
    category: ""
  },
  {
    id: 16,
    displayName: "Legacy Admin Role VinConnect Legacy Roles",
    isPopular: false,
    category: ""
  },
  {
    id: 17,
    displayName: "Legacy Manager Role VinConnect Legacy Roles",
    isPopular: false,
    category: ""
  },
  {
    id: 18,
    displayName: "Legacy Salesperson Role VinConnect Legacy Roles",
    isPopular: false,
    category: ""
  },
  {
    id: 19,
    displayName: "Legacy None Role VinConnect Legacy Roles",
    isPopular: false,
    category: ""
  },
  {
    id: 20,
    displayName: "Access all customers",
    isPopular: false,
    category: ""
  },
  {
    id: 21,
    displayName: "Reassign salesperson",
    isPopular: false,
    category: ""
  },
  {
    id: 34,
    displayName: "View desking management report",
    isPopular: false,
    category: ""
  },
  {
    id: 35,
    displayName: "Manage desking templates",
    isPopular: false,
    category: ""
  },
  {
    id: 36,
    displayName: "Access desking",
    isPopular: false,
    category: ""
  },
  {
    id: 38,
    displayName: "Override overdue task block",
    isPopular: false,
    category: ""
  },
  {
    id: 39,
    displayName: "Unwind deals",
    isPopular: false,
    category: ""
  },
  {
    id: 40,
    displayName: "Complete showroom visits",
    isPopular: false,
    category: ""
  },
  {
    id: 41,
    displayName: "Delete showroom visits",
    isPopular: false,
    category: ""
  },
  {
    id: 42,
    displayName: "Mark deals as sold",
    isPopular: false,
    category: ""
  },
  {
    id: 43,
    displayName: "Lead bucket access",
    isPopular: false,
    category: ""
  },
  {
    id: 44,
    displayName: "Copy customers",
    isPopular: false,
    category: ""
  },
  {
    id: 45,
    displayName: "Edit tasks",
    isPopular: false,
    category: ""
  },
  {
    id: 46,
    displayName: "Dismiss tasks",
    isPopular: false,
    category: ""
  },
  {
    id: 47,
    displayName: "Access trade appraisals",
    isPopular: false,
    category: ""
  },
  {
    id: 48,
    displayName: "Push customer to DMS",
    isPopular: false,
    category: ""
  },
  {
    id: 49,
    displayName: "Approve vehicle as no longer owns",
    isPopular: false,
    category: ""
  },
  {
    id: 50,
    displayName: "Mark vehicle as no longer owns",
    isPopular: false,
    category: ""
  },
  {
    id: 51,
    displayName: "Access mobile apps",
    isPopular: false,
    category: ""
  },
  {
    id: 52,
    displayName: "Modify form packets",
    isPopular: false,
    category: ""
  },
  {
    id: 53,
    displayName: "Modify email templates",
    isPopular: false,
    category: ""
  },
  {
    id: 54,
    displayName: "Reactivate leads",
    isPopular: false,
    category: ""
  },
  {
    id: 55,
    displayName: "View SSN",
    isPopular: false,
    category: ""
  },
  {
    id: 56,
    displayName: "View vehicle cost",
    isPopular: false,
    category: ""
  },
  {
    id: 57,
    displayName: "Manage SSN",
    isPopular: false,
    category: ""
  },
  {
    id: 68,
    displayName: "Mark leads bad",
    isPopular: false,
    category: ""
  },
  {
    id: 69,
    displayName: "Mark leads lost",
    isPopular: false,
    category: ""
  },
  {
    id: 70,
    displayName: "Change custom process",
    isPopular: false,
    category: ""
  },
  {
    id: 71,
    displayName: "Access desk log",
    isPopular: false,
    category: ""
  },
  {
    id: 72,
    displayName: "Access service appt. dashboard",
    isPopular: false,
    category: ""
  },
  {
    id: 73,
    displayName: "View gross",
    isPopular: false,
    category: ""
  },
  {
    id: 74,
    displayName: "View equity",
    isPopular: false,
    category: ""
  },
  {
    id: 75,
    displayName: "Update ACV",
    isPopular: false,
    category: ""
  },
  {
    id: 100,
    displayName: "Access Facebook Messenger",
    isPopular: false,
    category: ""
  },
  {
    id: 101,
    displayName: "Push to credit portal",
    isPopular: false,
    category: ""
  },
  {
    id: 833,
    displayName: "Access Customer Intelligence",
    isPopular: false,
    category: ""
  },
  {
    id: 844,
    displayName: "Access/Enable Enterprise Customer",
    isPopular: false,
    category: ""
  },
  {
    id: 845,
    displayName: "Can Approve Customer Override",
    isPopular: false,
    category: ""
  },
  {
    id: 29233,
    displayName: "Access AMP Opportunity List",
    isPopular: false,
    category: ""
  },
  {
    id: 31116,
    displayName: "View OEM Launchpad Incentives",
    isPopular: false,
    category: ""
  }
];

export const newRole = {
  id: null,
  name: "",
  parentId: null,
  numUsersAssigned: 0,
  creationDate: "",
  dealerEditable: true,
  permissions: []
};

export const enterpriseUserList = {
  roles,
  users,
  dealers,
  permissions
};

function copyEnterpriseUserList(incrementPrincipalIdBy) {
  return enterpriseUserList.users.map(u => ({
    ...u,
    principalId: u.principalId + incrementPrincipalIdBy
  }));
}

// Returns a list of around 1000 users for local performance testing
// Loops over the list of enterpriseUsers above and repeats the data
// by incrementing the principal Id by 1000 for each iteration
// to assure the principal ID is unique.
// This avoids us having to store 1000 rows of mock data in here.
// Also includes roles, dealers, and permissions to fully simulate
// a call to the enterpriseUserList API.
export function getMock() {
  let users = [];

  users = users.concat(copyEnterpriseUserList(0));

  return {
    users,
    roles,
    dealers,
    permissions,
    orgId
  };
}
