import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import ErrorBoundary from "./components/ErrorBoundary";

window.mount = id => {
  const app = document.getElementById(id);
  render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    app
  );
};
