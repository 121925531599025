import { string, bool, number, shape, arrayOf } from "prop-types";

export const dealer = shape({
  id: number.isRequired,
  name: string.isRequired
});

export const bulkOperation = shape({
  action: string.isRequired
});

export const loggedInUser = shape({
  id: number.isRequired,
  dealers: arrayOf(dealer)
});

export const permission = shape({
  id: number,
  displayName: string,
  isPopular: bool,
  category: string
});

export const role = shape({
  id: number,
  dealerEditable: bool.isRequired,
  permissions: arrayOf(Number).isRequired,
  name: string.isRequired,
  value: number
});

export const dealerRole = shape({
  id: number.isRequired,
  roleId: number.isRequired,
  permissions: shape({
    granted: arrayOf(number),
    revoked: arrayOf(number)
  })
});

export const user = shape({
  name: string.isRequired,
  userName: string.isRequired,
  dealers: arrayOf(dealerRole).isRequired,
  principalId: number.IsRequired
});
