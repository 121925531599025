/* eslint-disable react/no-multi-comp */
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { user, dealer } from "../propTypes";
import "./UserList.scss";
import UserListCell from "./UserListCell";
import { VariableSizeGrid as Grid } from "react-window";
import Button from "@cx/ui/Button";
import IconKeyboardArrowUp from "@cx/ui/Icons/IconKeyboardArrowUp";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import SetMultipleDealerRolesConfirmDialog from "./SetMultipleDealerRolesConfirmDialog";
import { dynamicSort } from "../utils/arrays";
import cx from "classnames";

const UserList = ({
  users,
  dealers: allDealers,
  disableFields,
  dispatch,
  multiUser,
  roles,
  allRoles,
  width,
  height,
  updateUserRole,
  removePrincipalPermissions,
  updatePrincipalPermission,
  selectAll
}) => {
  // Set to true to display confirmation dialog when changing permissions for a user assigned to multiple dealers
  const [
    showMultiDealerConfirmDialog,
    setShowMultiDealerConfirmDialog
  ] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  // The user, role, and dealer assigments that are about to be changed. Must be stored in state so we can display
  // a confirmation first when multiple dealers are assigned.
  const [roleToChange, setRoleToChange] = useState(null);

  if (users.length === 0) return null;

  // Calculate the width of each column in pixels, based on the percent width allocated to each column.
  // Hack: To avoid a horizontal scrollbar on Windows Chrome, the percents here must total to 98% instead of 100%
  const columnWidths = [0.04, 0.3, 0.15, 0.15, 0.1, 0.25].map(columnWidth =>
    parseInt(columnWidth * width, 10)
  );

  // Display multi-dealer confirmation if more than one dealer is assigned to the user.
  // Otherwise, set role without confirmation.
  function handleChangeUserRole(roleId, user) {
    if (user.dealers.length === 1) return changeUserRole(roleId, user);
    setRoleToChange({ role: roles.find(r => r.id === roleId), user });
    setShowMultiDealerConfirmDialog(true);
  }

  const changeUserRole = async (roleId, user) => {
    const principalDealersToUpdate = user.dealers.map(dealer => {
      return {
        user,
        dealerId: dealer.id,
        originalPermissions: {
          granted: dealer.permissions.granted,
          revoked: dealer.permissions.revoked
        }
      };
    });
    try {
      await updateUserRole(user, roleId);
      await updatePrincipalPermission(principalDealersToUpdate);
      dispatch(["SET_USER_ROLE", { user, roleId }]);
      return setShowMultiDealerConfirmDialog(false);
    } catch (error) {
      dispatch(["API_ERROR", error.message]);
    }
  };

  function toggleSelectAllUsers(event) {
    event.target.checked
      ? dispatch(["SELECT_ALL_USERS", users])
      : dispatch(["DESELECT_ALL_USERS", users]);
  }

  function renderSort(_sortBy) {
    return (
      <React.Fragment>
        <Button
          className={cx({
            userList__sortArrow: true, // eslint-disable-line camelcase
            userList__sortUpArrowButton: true, // eslint-disable-line camelcase
            "userList__sortArrow--active": sortBy === _sortBy
          })}
          buttonStyle="link"
          aria-label={"Sort by " + _sortBy + " ascending"}
          onClick={() => setSortBy(_sortBy)}
        >
          <IconKeyboardArrowUp />
        </Button>

        <Button
          className={cx({
            userList__sortArrow: true, // eslint-disable-line camelcase
            userList__sortDownArrowButton: true, // eslint-disable-line camelcase
            "userList__sortArrow--active": sortBy === "-" + _sortBy
          })}
          buttonStyle="link"
          aria-label={"Sort by " + _sortBy.replace("-", "") + " descending"}
          onClick={() => setSortBy("-" + _sortBy)}
        >
          <IconKeyboardArrowDown />
        </Button>
      </React.Fragment>
    );
  }

  const sortedUsers = sortBy ? [...users].sort(dynamicSort(sortBy)) : users;

  return (
    <Fragment>
      {showMultiDealerConfirmDialog && (
        <SetMultipleDealerRolesConfirmDialog
          showAssignByDealer={() => {
            setShowMultiDealerConfirmDialog(false);
            dispatch(["SHOW_ASSIGN_BY_DEALER", { user: roleToChange.user }]);
          }}
          changeUserRole={changeUserRole}
          onClickClose={() => setShowMultiDealerConfirmDialog(false)}
          allDealers={allDealers}
          user={roleToChange.user}
          role={roleToChange.role}
        />
      )}
      {/* Separate table to simulate sticky headers. This won't scroll out of view */}
      <table className="userList__stickyHeader">
        <thead>
          <tr>
            <td style={{ width: columnWidths[0], textAlign: "center" }}>
              {multiUser && (
                <input
                  type="checkbox"
                  onChange={toggleSelectAllUsers}
                  aria-label="Select all users"
                  checked={selectAll}
                />
              )}
            </td>
            <td style={{ width: columnWidths[1] }}>
              Name {renderSort("name")}
            </td>
            <td style={{ width: columnWidths[2] }}>
              Username {renderSort("userName")}
            </td>
            <td style={{ width: columnWidths[3] }}>
              Bridge Username {renderSort("bridgeUserName")}
            </td>
            <td style={{ width: columnWidths[4] }}>Dealers</td>
            <td style={{ width: columnWidths[5] }}>Role</td>
          </tr>
        </thead>
      </table>
      <Grid
        // Re-mount when width changes to force a re-render
        key={width}
        columnCount={6}
        className="userList__grid"
        columnWidth={index => columnWidths[index]}
        height={height}
        rowCount={users.length}
        rowHeight={() => 35}
        width={width}
      >
        {props => {
          return (
            <UserListCell
              {...props}
              roles={roles}
              allRoles={allRoles}
              users={sortedUsers}
              dispatch={dispatch}
              allDealers={allDealers}
              disableFields={disableFields}
              hoveredRowIndex={hoveredRowIndex}
              setHoveredRowIndex={setHoveredRowIndex}
              multiUser={multiUser}
              onChangeUserRole={handleChangeUserRole}
              removePrincipalPermissions={removePrincipalPermissions}
            />
          );
        }}
      </Grid>
    </Fragment>
  );
};

UserList.propTypes = {
  allRoles: PropTypes.array.isRequired,
  dealers: PropTypes.arrayOf(dealer).isRequired,
  disableFields: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  multiUser: PropTypes.bool,
  removePrincipalPermissions: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  selectAll: PropTypes.bool.isRequired,
  updatePrincipalPermission: PropTypes.func.isRequired,
  updateUserRole: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(user).isRequired,
  width: PropTypes.number.isRequired
};

UserList.defaultProps = {
  disableFields: false,
  multiUser: false,
  multiDealer: false
};

export default React.memo(UserList);
