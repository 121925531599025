import React, { Fragment } from "react";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";
import TextInput from "@cx/ui/TextInput";
import PropTypes from "prop-types";
import { role, permission } from "../propTypes";
import SelectInput from "@cx/ui/SelectInput";
import { arraysEqual } from "../utils/arrays";
import PermissionCategories from "./PermissionCategories";
import { customRoleNameIsUnique } from "../utils/roles";
import { getFilteredPermissions } from "../utils/permissions";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import Button from "@cx/ui/Button";
import EnterprisePermissionsList from "./EnterprisePermissionsList";
import "./ManageRoleModal.scss";

class ManageRoleModal extends React.Component {
  static defaultProps = {
    permissions: []
  };

  constructor(props) {
    super(props);
    this.filteredPermissions = this.filteredPermissions.bind(this);
    this.state = {
      parentRole: this.props.role.id
        ? this.props.roles.find(r => r.id === this.props.role.parentId)
        : null,
      role: { ...this.props.role },
      permissions: [...this.props.permissions],
      selectedPermissions: [...this.props.role.permissions],
      errors: {},
      permissionFilter: "",
      isSaving: false,
      enterprisePermissions: [],
      filteredPermissions: [this.props.permissions]
    };
  }

  componentDidMount() {
    this.filteredPermissions();
  }

  filteredPermissions = () => {
    const permissions = getFilteredPermissions(
      this.state.permissions,
      this.state.permissionFilter
    );

    if (this.props.displayShowUserType === false) {
      this.setState({ filteredPermissions: permissions });
      return permissions;
    }
    const combinedPermissions = [
      "view gross",
      "view appraisals (acv)",
      "access trade appraisals",
      "view equity",
      "view desking management report",
      "access/enable enterprise customer"
    ];

    //  permissions that are both crm and enterprise
    //  are filtered to their own seperate list
    const ecPermissions = permissions.filter(p => {
      return combinedPermissions.includes(p.displayName.toLowerCase());
    });
    // remaining permissions to display
    const filtered = permissions.filter(
      p => !combinedPermissions.includes(p.displayName.toLowerCase())
    );

    this.setState({
      enterprisePermissions: ecPermissions,
      filteredPermissions: filtered
    });
    return permissions;
  };

  onRoleChange = event => {
    // if user just changed their role selection back to the default value, reset to defaults.
    if (event.target.value === "") {
      return this.setState({
        parentRole: null,
        selectedPermissions: [...this.props.role.permissions]
      });
    }

    const parentRole = this.props.roles.find(
      p => p.id === parseInt(event.target.value, 10)
    );

    this.setState({
      parentRole,
      selectedPermissions: [...parentRole.permissions]
    });
  };

  onPermissionChange = event => {
    const { value, checked } = event.target;
    this.setState(state => {
      const selectedPermissions = checked
        ? [...state.selectedPermissions, parseInt(value, 10)]
        : state.selectedPermissions.filter(id => id !== parseInt(value, 10));
      return { selectedPermissions };
    });
  };

  onRoleNameChange = event => {
    const { crmUser } = this.props;
    const name = event.target.value;
    const errors = {};
    const nameHasChanged = name !== this.props.role.name;
    const reservedNames = crmUser
      ? ["enterprisemanager", "enterprisesalesperson"]
      : ["admin", "salesperson", "manager", "none"];

    if (
      nameHasChanged &&
      reservedNames.includes(
        name
          .toLowerCase()
          .trim()
          .replace(/ +/g, "")
      )
    )
      errors.roleName = "This role name is reserved and can not be used.";

    if (nameHasChanged && !customRoleNameIsUnique(name, this.props.roles))
      errors.roleName = "Enter a unique role name";

    this.setState(prevState => {
      return { role: { ...prevState.role, name }, errors };
    });
  };

  onPermissionFilterChange = event => {
    this.setState({ permissionFilter: event.target.value });
  };

  onClickReset = () =>
    this.setState(state => {
      return { selectedPermissions: state.parentRole.permissions };
    });

  // Name, parentRole, and at least one permission are required. And there must be no validation errors. Disable during save.
  enableSave() {
    return (
      this.state.role.name.length > 0 &&
      this.state.parentRole &&
      !arraysEqual(
        this.state.selectedPermissions,
        this.props.role.permissions
      ) &&
      this.state.selectedPermissions.length > 0 &&
      Object.keys(this.state.errors).length === 0 &&
      !this.state.isSaving
    );
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    this.props.onClickSave({
      ...this.state.role,
      name: this.state.role.name.trim(),
      permissions: this.state.selectedPermissions,
      parentId: this.state.parentRole.id
    });
  };

  isDefault = () => {
    if (!this.state.parentRole) return true;
    return arraysEqual(
      this.state.selectedPermissions,
      this.state.parentRole.permissions
    );
  };

  render() {
    const { roles, hideModal, onClickCancel, displayShowUserType } = this.props;

    const {
      enterprisePermissions,
      errors,
      filteredPermissions,
      isSaving,
      parentRole,
      permissionFilter,
      role,
      selectedPermissions
    } = this.state;

    const nonUserEditableRoles = roles
      .filter(r => r.dealerEditable !== true)
      .map(role => ({
        value: role.id,
        label: role.name
      }));

    return (
      <Fragment>
        <ModalDialog
          htmlId={
            displayShowUserType ? "ManageRoleModal__EC" : "ManageRoleModal"
          }
          show
          onHide={hideModal}
          header={
            <ModalDialog.Title>
              {this.props.role.id ? "Edit Role" : "Add Role"}
            </ModalDialog.Title>
          }
          footer={
            <div>
              <hr />
              <SubmitButton
                htmlId="ManageRoleModal-reset"
                buttonStyle="default"
                type="submit"
                onClick={this.onClickReset}
                disabled={this.isDefault()}
                className="ManageRoleModal__reset"
              >
                Reset to Default
              </SubmitButton>
              <SubmitButton
                htmlId="ManageRoleModal-cancel"
                buttonStyle="default"
                type="submit"
                onClick={onClickCancel}
                disabled={isSaving}
              >
                Cancel
              </SubmitButton>
              <SubmitButton
                htmlId="ManageRoleModal-save"
                buttonStyle="primary"
                type="submit"
                className="btn--loader"
                onClick={this.handleSave}
                disabled={!this.enableSave()}
              >
                {isSaving ? (
                  <Fragment>
                    <LoadingIndicator
                      htmlId="ManageRoleModalSaveLoadingIndicator"
                      size="small"
                      color="white"
                    />{" "}
                    Saving
                  </Fragment>
                ) : (
                  "Save"
                )}
              </SubmitButton>
            </div>
          }
        >
          <div style={{ marginLeft: "50px" }}>
            <TextInput
              className="modal__roleName__input"
              label="Role&nbsp;Name"
              htmlId="ManageRoleModal-RoleName"
              name="name"
              readOnly={this.props.role.id}
              onChange={this.onRoleNameChange}
              value={role.name}
              error={errors.roleName}
              helpertext="This field is mandatory"
            />
            <SelectInput
              className="modal__basedOn__input"
              htmlId="ManageRoleModal-ParentRoleDropDown"
              name="RoleDropdown"
              displayPlaceholder
              readOnly={this.props.role.id}
              label="Based&nbsp;on"
              onChange={this.onRoleChange}
              value={parentRole ? String(parentRole.id) : "0"}
              options={nonUserEditableRoles}
            />
          </div>
          {parentRole && (
            <React.Fragment>
              <hr />
              <div className="permissions__section">
                <div>
                  <h4>Permissions</h4>
                </div>
                <div className="filter__section">
                  <TextInput
                    htmlId="permissionmodal-permission-filter"
                    label="Filter Permissions"
                    layout="horizontal"
                    maxLength={50}
                    onChange={this.onPermissionFilterChange}
                    value={permissionFilter}
                    name="permissionFilter"
                  >
                    <Button
                      htmlId="manageRoleModalClearFilterButton"
                      onClick={() => this.setState({ permissionFilter: "" })}
                    >
                      Clear Filter
                    </Button>
                  </TextInput>
                </div>
              </div>
              <div className="permissions__container">
                <div
                  className={displayShowUserType ? "permissions__main" : null}
                >
                  <PermissionCategories
                    parentRole={parentRole}
                    onPermissionChange={this.onPermissionChange}
                    permissions={getFilteredPermissions(
                      filteredPermissions,
                      permissionFilter
                    )}
                    selectedPermissions={selectedPermissions}
                  />
                </div>
                {parentRole.name &&
                  displayShowUserType &&
                  parentRole.name.toLowerCase() !== "none" && (
                    <EnterprisePermissionsList
                      parentRole={parentRole}
                      permissions={getFilteredPermissions(
                        enterprisePermissions,
                        permissionFilter
                      )}
                      onPermissionChange={this.onPermissionChange}
                      selectedPermissions={selectedPermissions}
                    />
                  )}
              </div>
              <div style={{ float: "right" }}>* Changed</div>
            </React.Fragment>
          )}
        </ModalDialog>
      </Fragment>
    );
  }
}

ManageRoleModal.propTypes = {
  crmUser: PropTypes.bool.isRequired,
  displayShowUserType: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(permission).isRequired,
  role: role.isRequired,
  roles: PropTypes.arrayOf(role).isRequired
};

export default ManageRoleModal;
