import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import SubmitButton from "@cx/ui/SubmitButton";
import { role, dealer, user } from "../propTypes";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

const SetMultiDealerConfirmDialog = props => {
  const [isSaving, setIsSaving] = useState(false);

  function handleChangeUserRole() {
    setIsSaving(true);
    props.changeUserRole(props.role.id, props.user);
    // The component instance is deleted upon close, so no need to hide isSaving when call above completes.
    // It will be reset to false when a new instance of this dialog is created if someone opens another instance later.
  }

  return (
    <ModalDialog
      htmlId="setMultipleDealerRolesConfirmDialog"
      show
      onHide={props.onClickClose}
      header={<ModalDialog.Title>Multiple Dealers</ModalDialog.Title>}
      footer={
        <Fragment>
          <Button
            htmlId="setMultipleDealerRolesConfirmDialogSetDiffRoles"
            buttonStyle="link"
            disabled={isSaving}
            onClick={props.showAssignByDealer}
          >
            No, set different roles for each dealer
          </Button>
          <SubmitButton
            htmlId="setMultipleDealerRolesConfirmDialogSetRole"
            buttonStyle="primary"
            disabled={isSaving}
            type="submit"
            className="btn--loader"
            onClick={handleChangeUserRole}
          >
            {isSaving ? (
              <Fragment>
                <LoadingIndicator
                  htmlId="PermissionModalSaveLoadingIndicator"
                  size="small"
                  color="white"
                />{" "}
                Setting role for these dealers
              </Fragment>
            ) : (
              "Yes, set role for these dealers"
            )}
          </SubmitButton>
        </Fragment>
      }
    >
      <Fragment>
        <p>
          Set <strong>{props.user.userName}</strong> to{" "}
          <strong>{props.role.name}</strong> for these dealers?
        </p>
        <ul>
          {props.user.dealers.map(ud => {
            const dealer = props.allDealers.find(dealer => ud.id === dealer.id);
            return <li key={dealer.name}>{dealer.name}</li>;
          })}
        </ul>
      </Fragment>
    </ModalDialog>
  );
};

SetMultiDealerConfirmDialog.propTypes = {
  allDealers: PropTypes.arrayOf(dealer).isRequired,
  changeUserRole: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  role: role.isRequired,
  showAssignByDealer: PropTypes.func.isRequired,
  user: user.isRequired
};

export default SetMultiDealerConfirmDialog;
