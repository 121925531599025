export function customRoleNameIsUnique(roleName, roles) {
  return !roles
    .map(r => r.name.toLowerCase().trim())
    .includes(roleName.toLowerCase().trim());
}

// Case insensitive sort by name
export function sortRoles(a, b) {
  a.name.localeCompare(b.name, "en", {
    sensitivity: "base"
  });
}

export function changedFromDefault(permissionId, checked, parentRole) {
  if (!parentRole) return false;
  const permissionIdInParentRole = parentRole.permissions.includes(
    permissionId
  );
  return checked ? !permissionIdInParentRole : permissionIdInParentRole;
}

export function annotateRoles(roles) {
  const crmRoles = ["Admin", "Salesperson", "Manager", "None"];
  const systemRolesList = roles
    .filter(role => role.parentId === null)
    .map(r => {
      return {
        ...r,
        roleType: crmRoles.includes(r.name) ? 1 : 2
      };
    });

  const nonSystemRolesList = roles
    .filter(role => role.parentId !== null)
    .map(r => {
      const parentRole = systemRolesList.find(el => el.id === r.parentId);

      return {
        ...r,
        roleType: parentRole.roleType
      };
    });

  return systemRolesList.concat(nonSystemRolesList);
}
