import { arraysEqual } from "../../utils/arrays";
/* eslint-disable no-case-declarations */

export const initialState = {
  dealers: [],
  initialDealers: [],
  errors: {},
  dealerFilter: "",
  isSaving: false,
  isDisabled: true,
  showMultiDealerWarning: true
};

// Determines the selected dealers for the dealers modal
// This will check to see what users currently have access to and check the boxes based on their access.
const determineSelectedDealers = (users, dealers, crmView) => {
  if (users.length === 1) {
    // If it's just one user, then go ahead and
    // return that users dealers for the checkbox.
    // We need to make sure the key is the dealer id
    // for figuring out what dealers have been checked later.
    return {
      dealers: dealers.map(dealer => ({
        ...dealer,
        key: dealer.id,
        selected: crmView
          ? users[0].dealers.some(
              d => d.id === dealer.id && d.hasCrmAccess === true
            )
          : users[0].dealers.some(
              d => d.id === dealer.id && d.hasCrmAccess === false
            )
      })),
      showMultiDealerWarning: false
    };
  } else if (users.length > 1) {
    return determineMultiUserSelectedDealers(users, dealers, crmView);
  }
};

const determineMultiUserSelectedDealers = (users, dealers, crmView) => {
  let selectedDealers = [];

  // Loop through all the users
  for (let x = 0; x < users.length; x++) {
    const user = users[x];

    // Get current users selected dealers
    const selectedUserDealers = dealers.map(dealer => ({
      ...dealer,
      key: dealer.id,
      selected: user.dealers.some(
        ud => ud.id === dealer.id && ud.hasCrmAccess === crmView
      )
    }));

    if (x === 0) {
      // selected dealers has never been set
      selectedDealers = selectedUserDealers;
    } else {
      // Loop through all selected dealers and validate this user has those same CRM dealers selected
      for (let i = 0; i < selectedUserDealers.length; i++) {
        if (
          !selectedDealers.some(
            d =>
              d.id === selectedUserDealers[i].id &&
              d.selected === selectedUserDealers[i].selected
          )
        ) {
          // One dealer wasn't selected, show the multi dealer warning
          // and also uncheck all dealers.
          return {
            dealers: dealers.map(dealer => ({
              ...dealer,
              key: dealer.id,
              selected: false
            })),
            showMultiDealerWarning: true
          };
        }
      }
    }
  }

  // Return all selected dealers and don't show the warning.
  return { dealers: selectedDealers, showMultiDealerWarning: false };
};

export function dealersModalReducer(state, [actionType, payload]) {
  switch (actionType) {
    case "LOAD_INITIAL_DATA":
      const initResult = determineSelectedDealers(
        payload.users,
        payload.dealers,
        payload.crmView
      );

      return {
        ...state,
        dealers: initResult.dealers,
        initialDealers: initResult.dealers.reduce(
          (out, d, index) => (d.selected ? out.concat(d.key) : out),
          []
        ),
        showMultiDealerWarning: initResult.showMultiDealerWarning
      };
    case "RESET_TO_DEFAULT":
      const resetResult = determineSelectedDealers(
        payload.users,
        payload.dealers,
        payload.crmView
      );

      return {
        ...state,
        dealers: resetResult.dealers,
        showMultiDealerWarning: resetResult.showMultiDealerWarning,
        isDisabled: true
      };
    case "SET_LOADING_INDICATOR":
      return {
        ...state,
        isSaving: true
      };
    case "RESET_DEALERS_FILTER":
      return {
        ...state,
        dealerFilter: ""
      };

    case "SELECT_DEALER":
      const filteredSelectedDealers = payload.filteredDealers.filter(
        (fd, index) => {
          return payload.selectedRows.some(sr => sr.id === fd.id);
        }
      );

      return {
        ...state,
        dealers: state.dealers.map(d => {
          return filteredSelectedDealers.some(fd => fd.id === d.id)
            ? { ...d, selected: true }
            : payload.filteredDealers.some(fds => fds.id === d.id)
            ? { ...d, selected: false }
            : d;
        }),
        isDisabled: arraysEqual(state.initialDealers, payload.selectedRowKeys)
      };

    default:
      throw new Error("Unknown action type: " + actionType);
  }
}
