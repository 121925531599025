export function arraysEqual(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.sort().every(function(value, index) {
      return value === array2.sort()[index];
    })
  );
}

// Sort an array of objects by the property passed, case insensitive
// Slightly modified version of this (to be case insensitive): https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
export function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1); // eslint-disable-line no-param-reassign
  }
  return function(a, b) {
    // Do case insensitive comparison by swapping to lowercase.
    const aLower =
      typeof a[property] === "string" ? a[property].toLowerCase() : a;
    const bLower =
      typeof b[property] === "string" ? b[property].toLowerCase() : b;
    const result = aLower < bLower ? -1 : aLower > bLower ? 1 : 0;
    return result * sortOrder;
  };
}
